.sidebar {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  overflow-x: hidden;
  width: 250px;
  color: white;
  z-index: 1001;
  backface-visibility: hidden;
  perspective: 1000;
  transform: translate3d(0, 0, 0);
  transition: all 0.35s;
  overflow: hidden;
}

.sidebar .legal-info {
  padding: 15px 6px;
}

.sidebar-content {
  height: calc(100% - 60px);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.sidebar-header {
  line-height: 60px;
  font-size: 23px;
  padding: 0px 15px;
  position: relative;
}

.sidebar-header-title {
  width: 190px;
}

.sidebar-header i {
  font-size: 14px;
  position: absolute;
  right: 15px;
  top: 25px;
  opacity: 0.6;
  cursor: pointer;
}

.sidebar-header i:hover {
  opacity: 1;
}

.sidebar-logout {
  margin: 15px;
}

.sidebar-logo {
  background: url("/images/Login-SL8-Logo@2x.png") no-repeat center left;
  background-size: 120px auto;
  width: 120px;
  height: 60px;
  margin-left: 5px;
}

.sidebar-nav {
  list-style-type: none;
  margin: 0;
  padding: 0px;
  width: 250px;
}

.sidebar-nav-cont {
  display: flex;
  align-items: center;
}

.sidebar-nav-icon {
  width: 20px;
  display: flex;
  justify-content: center;
  margin-right: 15px;
}

.sidebar-nav-item {
  position: relative;
  cursor: pointer;
}

.sidebar-nav-item:after {
  display: block;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  transition: all 0.4s;
}

.sidebar-nav-item:hover:after {
  width: 100%;
  right: 0;
  transition: all 0.4s;
}

.sidebar-nav-link {
  position: relative;
  display: block;
  padding: 10px 20px;
  z-index: 1;
}
.sidebar-nav-link:hover {
  text-decoration: none;
}

.sidebar-nav ul {
  list-style-type: none;
  padding-left: 0px;
}
.sidebar-nav ul li {
  padding-left: 30px;
}

.sidebar-nav-link .fa {
  font-size: 20px;
}

.sidebar-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1001;
  background-color: transparent;
}

.collapse-icon {
  position: absolute;
  width: 40px;
  height: 60px;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.2s;
  color: #f15a29;
}

.sidebar-account {
  position: relative;
  background-color: #39383a;
  transition: all 0.2s;
}

.sidebar-account-inner {
  padding: 18px;
}

.sidebar-account:hover {
  cursor: pointer;
  background-color: #565556;
}

.sidebar-account-image {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  margin: 0px auto;
  transition: all 0.2s;
  background-size: 80px;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  line-height: 78px;
  font-size: 40px;
}

.sidebar-account-name {
  text-align: center;
  max-width: 200px;
  margin: 15px auto 0px auto;
  max-height: 100px;
  height: auto;
  transition: all 0.2s;
  opacity: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: normal;
}

.sidebar-account-icon {
  position: absolute;
  width: 40px;
  height: 44px;
  top: 5px;
  right: 0px;
  opacity: 0.6;
  transition: all 0.2s;
  display: block;
  cursor: pointer;
  text-align: center;
}

.sidebar-account-icon .fa {
  line-height: 44px;
  cursor: pointer;
}

.sidebar-account-icon:hover {
  opacity: 0.8;
  transition: all 0.2s;
}

@media screen and (min-width: 992px) {
  .sidebar-overlay-inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0);
    z-index: 9;
  }

  .sidebar-collapsed .sidebar {
    width: 60px;
    transition: all 0.35s;
  }

  .sidebar-collapsed .sidebar.hover {
    width: 250px;
    transition: all 0.35s;
  }

  .sidebar-collapsed .sidebar-header .collapse-icon {
    opacity: 0;
    color: #fff;
  }
  .sidebar-collapsed .sidebar.hover .sidebar-header .collapse-icon {
    opacity: 0.6;
  }
  .sidebar-collapsed .sidebar.hover .sidebar-header .collapse-icon:hover {
    opacity: 1;
  }

  .sidebar-collapsed .sidebar .sidebar-logo {
    width: 25px;
    transition: all 0.3s;
  }

  .sidebar-collapsed .sidebar .sidebar-nav ul li {
    overflow: hidden;
    padding-left: 0px;
    transition: all 0.3s;
  }

  .sidebar-collapsed .sidebar .sidebar-nav-title {
    opacity: 0;
    transition: all 0.3s;
  }

  .sidebar-collapsed .sidebar.hover .sidebar-logo {
    width: 120px;
    transition: all 0.3s;
  }

  .sidebar-collapsed .sidebar.hover .sidebar-nav ul li {
    padding-left: 30px;
    transition: all 0.3s;
  }

  /* For Explorer and Edge */
  @supports (-ms-ime-align: auto) {
    .sidebar-collapsed .sidebar:hover .sidebar-nav ul li {
      padding-left: 30px;
      transition: all 0.3s;
    }
  }

  .sidebar-collapsed .sidebar.hover .sidebar-nav-title {
    opacity: 1;
    transition: all 0.3s;
  }

  .sidebar-collapsed .sidebar-account-image {
    width: 24px;
    height: 24px;
    transition: all 0.2s;
    background-size: 24px;
    line-height: 24px;
    font-size: 16px;
  }

  .sidebar-collapsed .sidebar-account-name {
    max-width: 0px;
    overflow: hidden;
    transition: all 0.2s;
    max-height: 0;
    margin-top: 0;
    opacity: 0;
  }

  .sidebar-collapsed .sidebar-account-icon {
    opacity: 0;
    transition: all 0.2s;
  }

  .sidebar-collapsed .sidebar.hover .sidebar-account-image {
    width: 80px;
    height: 80px;
    transition: all 0.2s;
    background-size: 80px;
    line-height: 78px;
    font-size: 40px;
  }

  .sidebar-collapsed .sidebar.hover .sidebar-account-name {
    margin-top: 15px;
    max-width: 200px;
    max-height: 100px;
    height: auto;
    transition: all 0.2s;
    opacity: 1;
  }

  .sidebar-collapsed .sidebar.hover .sidebar-account-icon {
    opacity: 0.6;
    transition: all 0.2s;
  }

  .sidebar-collapsed .sidebar.hover .sidebar-account-icon:hover {
    opacity: 0.8;
  }

  .sidebar-collapsed .sidebar a .fa {
    pointer-events: none;
  }

  .sidebar-collapsed .sidebar.hover a .fa {
    pointer-events: initial;
  }

  .sidebar-collapsed .legal-info {
    opacity: 0;
  }

  .sidebar-collapsed .sidebar.hover .legal-info {
    opacity: 1;
  }
}

@media screen and (max-width: 991px) {
  .sidebar-header .collapse-icon {
    display: none;
  }

  .sidebar {
    width: 0px;
    transition: all 0.35s;
  }

  .sidebar-opened .sidebar {
    width: 250px;
    transition: all 0.35s;
  }

  .sidebar-account {
    width: 250px;
  }
}
