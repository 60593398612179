.dialog-error {
  z-index: 9999;
}
.dialog-error-title {
  background-image: url(/images/notification-sad.png);
  background-repeat: no-repeat;
  background-size: 32px 32px;
  background-position: 20px center;
  padding: 25px 25px 25px 70px;
  font-size: 24px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

}

.dialog-error-dismiss {
  position: absolute;
  right: 15px;
  top: 5px;
  font-size: 40px;
  line-height: 40px;
  cursor: pointer;
}

.dialog-error-body {
  padding: 100px 25px 25px 25px;
}

.dialog-error h1 {
  font-size: 24px;
  /*---- global font setting ----*/
  font-weight: 400;
  margin-top: 0;
}

.dialog-error-body-text {
  line-height: 1.4em;
  margin-bottom: 30px;
}

.dialog-error-done {
  text-align: right;
  margin-top: 20px;
}

.dialog-error-action {
  text-align: center;
  padding: 15px 0;
}

.dialog-error-issue {
  display: inline-block;
  height: 42px;
  line-height: 42px;
  vertical-align: top;
  font-size: 20px;
/*---- global font setting ----*/
  font-weight: 400;
  width: calc(90% - 150px);
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.dialog-error-issue > div {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-right: -4px;
  padding: 0 15px;
}

.dialog-error-issue-hint {
  position: absolute;
  bottom: -15px;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 11px;
  line-height: 12px;
/*---- global font setting ----*/
  font-weight: 400;
}


@media screen and (max-width: 767px) {
  .dialog-error-title {
    font-size: 14px;
    background-size: 16px 16px;
    background-position: 10px center;
    padding: 15px 15px 15px 36px;
  }

  .dialog-error-dismiss {
    font-size: 24px;
    right: 10px;
  }

  .dialog-error-body {
    padding: 60px 15px 15px 15px;
  }

  .dialog-error-body h1 {
    font-size: 14px;
/*---- global font setting ----*/
    font-weight: 400;
  }

  .dialog-error-body-text {
    font-size: 14px;
  }

  .dialog-error-action > div,
  .dialog-error-action > a {
    display: block;
    width: 100%!important;
  }

  .dialog-error-issue {
    height: auto;
  }

  .dialog-error-issue > div:first-child {
    line-height: 1.2em;
    padding: 15px;
    font-size: 14px;
    border-radius: 4px;
  }

  .dialog-error-issue-hint {
    position: relative;
    bottom: auto;
    font-size: 11px;
    margin: 5px 0 15px 0;
  }

  .dialog-error-done {
    text-align: center;
    margin-bottom: 10px;
  }
}