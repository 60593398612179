.verification-dialog .wizard-steps {
  max-width: 250px;
  margin: 30px auto !important;
}

.verification-dialog-target {
  font-size: 1.1em;
  margin: 30px 0;
  word-wrap: break-word;
}

.verification-dialog-submit-button {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.verification-dialog-body {
  margin: 0 auto;
  padding: 0 30px;
}

.verification-dialog-actions .btn {
  margin: 0 10px;
}

.verification-dialog-body form {
  margin: 0 auto;
}

.verification-dialog-footer {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.verification-code-timer {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.verification-dialog-footer > div {
  padding: 20px 30px;
}

.verification-dialog-footer .right {
  margin-left: auto;
}

.verification-dialog-footer .left {
  text-align: left;
  font-size: 14px;
}

.verification-dialog-footer .left p {
  margin: 2px 0;
}

@media screen and (max-width: 767px) {
  .verification-dialog-actions .btn {
    display: block !important;
    max-width: 200px;
    margin: 20px auto;
  }

  .verification-dialog-footer {
    display: block;
  }

  .verification-dialog-footer .left,
  .verification-dialog-footer .right {
    width: 100%;
    text-align: center;
  }

  .verification-dialog-target {
    font-size: 1.1em;
  }
}
