.login-start-logo {
  width: 250px;
  height: 105px;
  margin: 30px auto 50px auto;
  background: url(/images/Login-FullLogo.png) no-repeat center;
  background-size: 250px auto;
}

@media
(-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {
  .login-start-logo {
    background: url(/images/Login-FullLogo@2x.png) no-repeat center;
    background-size: 250px auto;
  }
}

@media screen and (max-width: 479px) {
  .login-start-logo {
    width: 200px;
    background-size: 200px auto;
    height: 85px;
    margin-bottom: 30px;
    margin-top: 10px;
  }
}