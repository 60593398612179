.page_profile-settings .card {
  margin-top: 0px!important;
}

.page_profile-settings .card:last-of-type {
  margin-bottom: 0px!important;
}

.page_profile-settings .avatar-preview > div:first-child {
  margin: 0px 10px 0px 0px!important;
  display: inline-block!important;
  vertical-align: middle;
}

.page_profile-settings form {
  width: 50%;
}

.page_profile-settings .btn {
  margin-top: 20px;
}

@media screen and (max-width: 767px) {
  .page_profile-settings form {
    width: 100%;
  }
}