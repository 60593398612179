@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700);
.slate-generator-title {
  margin-bottom: 0;
}

.slate-generator-container {
  padding-left: 30px;
}

.slate-generator-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.slate-generator-item-name {
  font-weight: 500;
  margin-right: 15px;
  width: 100px;
  text-align: right;
  padding: 3px 0;
}

.slate-generator-item-value {
  padding: 3px 0;
  word-break: break-all;
}

.slate-generator-warning-wrapper {
  margin-bottom: 20px;
}

@media screen and (max-width: 800px) {
  .slate-generator-container {
    padding: 0;
  }

  .slate-generator-item-name {
    width: auto;
  }
}

.slate-generator-qr-dev {
  width: 200px;
  height: 200px;
  max-height: 200px;
  position: relative;
  margin-top: 7px;
  margin-bottom: 30px;
}

.slate-generator-qr {
  width: 200px;
  height: 200px;
  max-height: 200px;
  position: relative;
  margin-top: 7px;
  margin-bottom: 30px;
}
.slate-generator-qr:before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  border: 5px solid #f15a29;
  border-color: #f15a29 #f15a29 transparent #f15a29;
  border-radius: 50%;
  -webkit-animation: loader 1s linear infinite;
          animation: loader 1s linear infinite;
}
.slate-generator-qr > img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
@-webkit-keyframes loader {
  0% {
    -webkit-transform: translate(-50%,-50%) rotate(0deg);
            transform: translate(-50%,-50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%,-50%) rotate(360deg);
            transform: translate(-50%,-50%) rotate(360deg);
  }
}
@keyframes loader {
  0% {
    -webkit-transform: translate(-50%,-50%) rotate(0deg);
            transform: translate(-50%,-50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%,-50%) rotate(360deg);
            transform: translate(-50%,-50%) rotate(360deg);
  }
}
.field {
  width: calc(100% - 10px);
  margin-left: 5px;
  margin-bottom: 5px;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 1px #cccccc;
  transition: all 0.2s;
  text-align: left;
}

.field:hover {
  cursor: pointer;
  background-color: #f7f6f6;
}

.field-disable.field:hover {
  cursor: auto;
  cursor: initial;
  background-color: #ffffff;
}

.field-title {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 18px;
}

.field-title-history {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.field-name {
  color: #8e8e93;
  margin-right: auto;
  padding-right: 5px;
}

.field-data-text {
  width: 60%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
}

.field-data-boolean {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #e6160d;
}

.field-data-boolean-true {
  background-color: #72bb53;
}

/* .field-data-boolean-checkbox {
  padding-top: 2px;
  margin-right: -2px;
} */

.field-info {
  text-align: right;
  margin-top: 3px;
}

.field-info-left {
  text-align: left;
}

/* .field-info-margin {
  margin-top: 8px;
} */

.field-info-coords {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.field-info-coords-margin {
  margin-bottom: 4px;
}

.field-info-address {
  margin-left: 5px;
}

.field-key,
.field-value {
  font-size: 10px;
  color: #414042;
  margin-left: 5px;
}

.field-key {
  color: #8e8e93;
}

.field-record-displayName,
.field-record-timestamp,
.field-record-key {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 1px;
}

.field-record-name {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
}

.field-record-timestamp {
  -webkit-flex-shrink: 10000;
          flex-shrink: 10000;
}

.field-record-displayName {
  -webkit-flex-shrink: 100000000;
          flex-shrink: 100000000;
}

.field-icon {
  font-size: 18px;
  color: #f15a29;
}

.field-data-more {
  color: #f15a29;
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none;
}

.field-image {
  position: relative;
  width: calc(100% + 30px);
  margin: 5px -15px;
  height: 180px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.field-journal-title {
  margin-bottom: 5px;
}

.field-journal-icon {
  margin-left: 12px;
}

.field-journal-long {
  padding: 10px 15px;
}

.field-journal-entry {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  color: #8e8e93;
  margin-bottom: 10px;
  padding: 15px;
  background-color: white;
  box-shadow: 0 1px 2px 1px #cccccc;
}

.field-journal-entry:last-child {
  margin-bottom: 5px;
}

.field-qr {
  display: -webkit-flex;
  display: flex;
}

.field-qr-code {
  width: 100px;
  height: 100px;
}

.field-qr-code-dialog {
  padding: 20px;
}

.field-image-preloader {
  position: static;
  position: initial;
}

.field-location-buttons {
  padding: 10px 0 0 10px;
}

.field-location-buttons-divider {
  display: inline-block;
  width: 1px;
  height: 34px;
  background-color: #eee;
  vertical-align: top;
}

.field-description {
  padding: 10px 10px 10px 15px;
  margin-bottom: 5px;
  font-size: 12px;
}

.hidden {
  display: none;
}

@media screen and (max-width: 400px) {
  .field-qr-code-dialog {
    padding: 10px;
  }
}

.tools-export-header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.tools-export-tab {
  min-height: 400px;
}

.tools-export-dialog-body {
  padding: 20px 24px;
  text-align: left;
}

.tools-export-bold {
  font-weight: 500;
}

.jobs-list-title {
  display: -webkit-flex;
  display: flex;
  margin-top: 20px;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.jobs-list-empty {
  font-size: 16px;
  padding-top: 10px;
}

.jobs-item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.jobs-list-title-name,
.jobs-list-title-date,
.jobs-item-name,
.jobs-item-date {
  width: 180px;
  padding-right: 10px;
}

.jobs-list-title-status,
.jobs-item-status-name,
.jobs-item-status-progress {
  width: 100px;
}

.jobs-item-status {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 220px;
  padding-right: 10px;
}

.jobs-list-title-name,
.jobs-list-title-date,
.jobs-list-title-status {
  font-weight: 500;
}

.jobs-item-title {
  width: 70px;
  font-weight: 500;
}

.jobs-item-buttons {
  display: -webkit-flex;
  display: flex;
  margin-left: auto;
}

.jobs-item-btn-text {
  margin-right: 10px;
}

.jobs-item-btn-icon {
  margin-right: 5px;
}

.jobs-item-btn-icon:last-child {
  margin-right: 0;
}

.disabled-link {
  pointer-events: none;
  cursor: default;
}

@media screen and (max-width: 1079px) {
  .jobs-item-buttons {
    margin-top: 10px;
  }
}

@media screen and (max-width: 640px) {
  .jobs-item {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }

  .jobs-item-name,
  .jobs-item-date,
  .jobs-item-status {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 478px) {
  .tools-export-header {
    display: block;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 420px) {
  .jobs-item-status-progress {
    width: 80px;
  }

  .jobs-item-buttons {
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-right: auto;
  }

  .jobs-item-buttons-actions {
    margin-top: 10px;
  }
}

.preloader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  overflow: hidden;
  background-color: rgba(238, 238, 238, 0.8);
}

.preloader-inner {
  -webkit-animation: anim 2s infinite linear;
  animation: anim 2s infinite linear;
  width: 51px;
  height: 44px;
  background-size: 51px 44px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -25px;
}

@-webkit-keyframes anim {
  from {
    -webkit-transform: rotateY(0deg);
  }
  to {
    -webkit-transform: rotateY(360deg);
  }
}

@keyframes anim {
  from {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  to {
    -webkit-transform: rotateY(360deg);
            transform: rotateY(360deg);
  }
}

.field-collapsible {
  position: relative;
  cursor: pointer;
}

.field-collapsible-disable {
  background-color: #f7f6f6;
  cursor: default;
}

.field-collapsible-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 1px solid #ccc;
  max-width: 600px;
}

.field-collapsible-data {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 10px 10px 10px 0;
}

.field-collapsible-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.field-collapsible-btn {
  margin-right: 10px;
  cursor: pointer;
}

/* .field-collapsible-btn {
  top: 8px;
  left: 6px;
} */

.field-collapsible-type {
  color: #1e92df;
  font-size: 12px;
  margin-left: 10px;
}

@media screen and (max-width: 600px) {
  .field-collapsible-container {
    max-width: 400px;
  }
}

@media screen and (min-width: 992px) {
  .icon-medium-screen-correction {
    padding-left: 20px;
  }
}

.icon-all-screens-correction {
  padding-left: 20px;
}

.search-error {
  color: rgb(241, 90, 41);
  font-size: 11px;
  position: absolute;
  bottom: -3px;
  left: 20px;
}

.search-error.hidden {
  display: none;
}

/* Important cause it should override other style with important */
.search-input-wrapper input {
  font-size: 17px !important;
}

.reset-form-button {
  position: absolute;
  top: 20px;
  right: -40px;
}

.settings-name-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.settings-basic-info-box {
  margin-top: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.settings-type-selector {
  margin-top: 20px;
  width: 100%;
}

.settings-refresh-checkbox {
  width: 280px !important;
}

.slate-view-refresh-checkbox {
  width: 165px !important;
  margin-right: 20px;
  padding: 4px;
  border-radius: 7px;
  border: solid 1px transparent;
  box-sizing: border-box;
}

.settings-refresh-checkbox-animation {
  width: 165px !important;
  margin-right: 20px;
  padding: 4px;
  border-radius: 7px;
  border: solid 1px rgb(241, 90, 41);
  box-sizing: border-box;
  background: white;
  background: linear-gradient(to left, white 50%, rgb(241, 90, 41, .5) 50%) right;
  background-size: 200%;
  animation: load-anim 30s infinite linear;
  -webkit-animation: load-anim 30s infinite linear;
}

@-webkit-keyframes load-anim {
  100%{
    background-position: left;
  }
}

@keyframes load-anim {
  100%{
    background-position: left;
  }
}

.settings-actions {
  position: relative;
  display: -webkit-flex;
  display: flex;
}

.settings-actions-wrapper {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  display: -webkit-flex;
  display: flex;
}

.settings-action-cancel-btn {
  margin-right: 20px;
}

@media screen and (max-width: 991px) {
  .reset-form-button {
    right: 0;
  }

  .search-error {
    left: 0;
    bottom: 0;
  }
}

@media screen and (max-width: 660px) {
  .settings-name-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .settings-source-selector {
    margin-top: 15px;
  }
}

.settings-source-selector {
  margin-left: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.settings-source-title {
  color: #c0c0c0;
  font-size: 16px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 86%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.settings-source-subtitle {
  width: 100%;
  font-size: 10px;
  padding-top: 5px;
}
.search-container {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
}

.available-rule-fields {
  max-height: 360px;
  overflow-y: auto;
}

/* Prevent selecting */
.slate-view-settings {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.settings-header {
/*---- global font setting ----*/
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 0;
}

.settings-rules-add {
  margin-top: 20px;
}

.settings-rules-operator {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  padding-right: 20px;
}

.settings-rules-operator-text {
  font-size: 16px;
/*---- global font setting ----*/
  font-weight: 400;
}

.settings-rule {
  position: relative;
  display: -webkit-flex;
  display: flex;
  min-height: 60px;
  padding-left: 10px;
  background-color: #eee;
  margin: 20px 0;
}

.settings-rule-items-container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  overflow: hidden;
}

.settings-rule-field {
  position: relative;
  margin-right: 30px;
}

.settings-rule-operator {
  margin: 0 30px 0 0;
}

.settings-rule-pattern-text {
  margin: -8px 30px 0 0;
}

.settings-rule-pattern-selector {
  margin: 0 30px 0 0;
}

/* TODO there is dublicate of max width from styles.js. Need to fix it */
.settings-rule-field,
.settings-rule-operator,
.settings-rule-pattern-text,
.settings-rule-pattern-selector {
  max-width: 220px;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.settings-rule-btn-remove {
  position: absolute;
  top: 6px;
  right: -3px;
}

.settings-record-btn-remove {
  position: absolute;
  top: 0;
  right: -50px;
}

.settings-columns-item-btn-remove {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.settings-rule-spinner {
  padding-top: 15px;
}

.settings-fields-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.settings-fields-available {
  margin-right: 50px;
}

.settings-fields-item {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 10px;
}

.settings-fields-item:last-child {
  margin-bottom: 0;
}

.settings-options {
  margin-left: 40px;
  margin-bottom: 10px;
}

.settings-text {
  font-size: 16px;
}

.settings-radio {
  margin: 10px 0 5px 0;
}

.settings-record-selector {
  position: relative;
  max-width: 360px;
}

@media screen and (max-width: 1160px) {
  .settings-rule,
  .settings-rule-items-container {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
  }

  .settings-rule {
    padding-right: 10px;
  }

  .settings-rule-field,
  .settings-rule-operator,
  .settings-rule-pattern-text,
  .settings-rule-pattern-selector {
    margin-right: 0;
    width: 100%;
  }

  .settings-rule-btn-remove {
    top: 0;
  }

  .settings-rule-date-type-fixed label {
    display: block !important;
  }
}

.date-picker {
  display: inline-block;
  vertical-align: top;
}

.pin-colors {
  border: 1px solid rgb(211, 211, 211);
  padding: 16px;
  margin: 16px 0;
  position: relative;
  min-height: 60px;
  padding-left: 10px;
  background-color: #fafafa;
}

@media screen and (max-width: 420px) {
  .settings-rule {
    padding-right: 40px;
  }
}

.blur {
  -webkit-filter: blur(.2rem);
          filter: blur(.2rem)
}

.settings-rule-content-container {
  position: relative;
}
.settings-fields {
  display: -webkit-flex;
  display: flex;
}

.settings-fields .separator {
  width: 50px;
  -webkit-align-self: center;
          align-self: center;
}

.settings-fields .separator > div {
  border: 1px solid #cccccc;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 28px;
  margin: 10px auto;
  cursor: pointer;
}

.settings-fields-column {
  width: calc((100% - 50px) / 2);
  max-width: 640px;
  height: 70vh;
}

.settings-fields-column-header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.settings-fields-column-title {
/*---- global font setting ----*/
  font-weight: normal;
}

.settings-fields-column-container {
  width: 100%;
  height: calc(100% - 96px);
  border: 1px solid #cccccc;
  border-top: none;
  overflow-y: auto;
}

.settings-fields-column-container ul {
  width: 100%;
}

.settings-fields-buttons {
  display: -webkit-flex;
  display: flex;
}

.settings-fields-button {
  width: 30px;
  height: 30px;
  border: 1px solid #cccccc;
  margin-left: 10px;
  text-align: center;
  line-height: 28px;
  cursor: pointer;
}

.settings-field-available {
  border-bottom: 1px solid #cccccc;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  display: -webkit-flex;
  display: flex;
  cursor: pointer;
  transition: background-color 0.2s;
}

.settings-field-available:hover {
  background-color: #c3e2f6;
}

.settings-field-available-highlighted {
  background: #a6d4f2 !important;
  border-bottom: 1px solid #69b6ea !important;
}

.settings-fields-nothing {
  padding: 10px;
}

.settings-field-header {
  padding-left: 10px;
  background-color: #f7f6f6;
  cursor: default;
}

.settings-field-header:hover {
  background-color: #f7f6f6;
}

.settings-field-selected {
  border-bottom: 1px solid #cccccc;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-content: center;
          align-content: center;
  /* padding: 10px 10px 10px 15px; */
}

.settings-field-selected-highlighted {
  background: #a6d4f2;
  border-bottom: 1px solid #69b6ea;
}

.settings-field-body {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.settings-field-title {
  width: 100%;
  display: block;
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
}

.settings-field-type {
  color: #0486dc;
  font-size: 12px;
}
.part-left,
.part-right {
  display: inline-block;
  width: 50%;
}
/* .available-fields-select-wrapper {
  margin-top: 13px;
  vertical-align: top;
  margin-bottom: 12px;
}
.available-fields-select-wrapper span {
  line-height: 30px;
  padding-right: 10px;
  color: rgb(65, 64, 66);
  font-weight: 500;
}
.available-fields-select-wrapper .flex {
  justify-content: flex-end;
  display: flex;
} */

@media screen and (max-width: 470px) {
  .part-left,
  .part-right {
    width: 100%;
  }
  .part-right {
    margin-bottom: 30px;
  }
  /* .available-fields-select-wrapper .flex {
    justify-content: flex-start;
  } */
}

@media screen and (max-width: 700px) {
  .settings-fields {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .settings-fields-column {
    width: 100%;
  }

  .settings-fields .separator {
    width: 80px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-top: 10px;
  }
}

/* @media screen and (min-width: 991px) and (max-width: 1100px) {
  .available-fields-select-wrapper span {
    display: none;
  }
} */

/* @media screen and (min-width: 700px) and (max-width: 885px) {
  .available-fields-select-wrapper span {
    display: none;
  }
} */

.select-wrapper {
  height: 30px;
  padding-bottom: 10px;
}

.color-picker {
  display: inline-block;
  margin: 0 10px;
}

.color-picker-swatch {
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
  display: inline-block;
  cursor: pointer;
}

.color-picker-color {
  width: 64px;
  height: 24px;
  border-radius: 2px;
}

.github-picker > span > div {
  /* Can't control inner GithubSwatch's styles props yet */
  width: 43px !important;
  height: 20px !important;
}

.footer {
  width: 100%;
  height: 60px;
  position: relative;
  box-shadow: -2px 0px 5px 0px #cccccc;
}

.footer:empty {
  display: none;
}

@media screen and (min-width: 992px) {
  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 12px 43px 12px 280px;
    /*background-color: #f7f6f6;*/
    /*border-top: 1px solid rgb(211, 211, 211);*/
    background-color: rgb(238, 238, 238);
    border-top: 1px solid rgb(230, 230, 230);
  }

  .container:not(.with-empty-footer) {
    height: calc(100% - 120px); /* header + footer height */
  }

  .sidebar-collapsed .footer {
    padding-left: 90px;
  }
}

.table-filter {
  max-width: 300px;
  width: 90%;
  margin: 20px auto;
}

.table-filter-input {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.table-filter-buttons {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 20px;
}

.image-loader {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.image-loader-inner {
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.5s;
}

.image-loader-inner-loaded {
  opacity: 1;
}

.expander {
  position: absolute;
  right: 0;
  top: 0;
  width: 26px;
  height: 26px;
  z-index: 1001;
  text-align: center;
  line-height: 26px;
  border-radius: 4px;
  margin: 5px;
  cursor: pointer;
}

.scrollable-table {
  height: 100%;
  position: relative;
}

.header-grid {
  overflow: hidden !important;
  background: #414042;
  color: #aaaaaa;
  outline: none !important;
}

.header-drag,
.header-drag .header-cell .header-drag .header-cell-filter,
.header-drag .react-resizable-handle {
  cursor: all-scroll;
}

.header-resize,
.header-resize .header-cell,
.header-resize .header-cell-filter,
.header-resize .react-resizable-handle {
  cursor: col-resize;
}

.body-grid {
  border: 1px solid #e6e6e6;
  border-top: 0;
  outline: none !important;
}

.header-row {
  display: block;
}

.header-cell {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: stretch;
          align-items: stretch;
  padding: 0 16px 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  background: #565556;
  height: 36px;
}

.header-cell.without-ordering {
  cursor: default;
}

.header-cell:before {
  display: block;
  content: '';
  height: 100%;
  background: #565556;
  position: absolute;
  left: -7px;
  width: 8px;
}

.header-grid .header-cell {
  background: #414142;
}

.header-grid .header-cell:before {
  display: none;
}

.header-cell-fixed {
  display: inline-block;
  vertical-align: top;
  width: 32px;
  height: 36px;
  padding: 0;
  border-right: 1px solid #565556;
}

.header-cell-title {
  line-height: 36px;
  width: calc(100% - 28px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.without-ordering .header-cell-title {
  padding-left: 5px;
}

.header-cell-actions {
  max-width: 44px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row-reverse;
          flex-flow: row-reverse;
}

.header-cell-sort,
.header-cell-filter {
  line-height: 36px;
  text-align: center;
}

.header-cell-sort {
  width: 14px;
}

.header-cell-filter {
  width: 30px;
}

.body-row {
  display: -webkit-flex;
  display: flex;
  background: #f7f6f6;
  cursor: pointer;
}

.body-row:last-child .body-row-back {
  border-bottom: 1px solid #eeeeee;
}

.body-row.even .body-row-back {
  background: #ffffff;
}

.body-row.body-row-selected .body-row-back {
  background: #a6d4f2;
  border-bottom: 1px solid #69b6ea;
}

.body-row.body-row-selected .body-cell-fixed {
  background: #2196f3;
}

.body-row.body-row-selected-first .body-row-back {
  border-top: 1px solid #69b6ea;
}

.body-row.body-row-selected .body-cell:after {
  background: #69b6ea;
}

.body-cell {
  position: relative;
  padding: 0 15px 0 4px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.body-cell.medium-height {
  line-height: 50px;
}

.body-cell.large-height {
  line-height: 120px;
}

.body-cell-fixed {
  -webkit-justify-content: center;
          justify-content: center;
  position: relative;
  width: 31px;
  height: auto;
  padding: 0;
  font-size: 10px;
  color: #c0c0c0;
  overflow: hidden;
}

.body-cell-fixed.medium-height {
  height: 70px;
}

.body-cell-fixed.large-height {
  height: 140px;
}

.body-cell-fixed-newline {
  position: absolute;
  top: -36px;
}

.body-cell-fixed-newline.medium-height {
  top: -70px;
}

.body-cell-fixed-newline.large-height {
  top: -140px;
}

.body-cell:nth-child(3) {
  padding-left: 10px;
}

.body-cell:after {
  content: '';
  display: block;
  height: 100%;
  width: 1px;
  background: #eeeeee;
  position: absolute;
  right: 6px;
  top: 0;
}

.body-cell.medium-height:after {
  height: 70px;
}

.body-cell.large-height:after {
  height: 140px;
}

.body-cell-fixed:after {
  right: 0;
}

.body-cell:last-child:after {
  display: none;
}

.body-cell:last-child {
  border-right: 0;
}

.body-cell-preview {
  width: 60%;
  background: #d9d9d9;
  height: 10px;
  border-radius: 4px;
  position: relative;
  top: 3px;
}

.body-cell-value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.body-cell-image {
  overflow: hidden;
}

.body-cell-value-inner-email {
  cursor: text;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 7px 0;
}

.body-cell-value-inner-email-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

/* for clipboard purposes */
.body-cell-value-inner {
  cursor: text;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.react-resizable {
  position: relative;
  opacity: 1 !important;
  z-index: 500;
  display: inline-block;
  vertical-align: top;
}

.header-row .react-resizable:last-child .header-cell {
  border-right: 0;
}

.react-resizable-handle {
  height: 36px !important;
  width: 16px !important;
  cursor: col-resize;
  background: none;
  right: 0;
  z-index: 9;
  padding: 0;
}

.without-ordering + .react-resizable-handle {
  cursor: default;
}

.react-resizable-handle:before {
  content: '';
  display: block;
  height: 100%;
  width: 8px;
  background: #414142;
  position: absolute;
  right: 0;
}

.react-resizable-handle:after {
  content: '';
  display: block;
  height: 100%;
  width: 1px;
  background: #565556;
  position: absolute;
  bottom: 0;
  right: 6px;
}

/* LOADING TABLE */
.loading-table {
  height: 100%;
  position: relative;
}

.loading-table-header,
.loading-table-row {
  display: -webkit-flex;
  display: flex;
  height: 36px;
}

.loading-table-header {
  background-color: #414142;
}

.loading-table-body {
  height: 100%;
  overflow-y: auto;
  border: 1px solid #e6e6e6;
}

.loading-table-row {
  border-bottom: 1px solid #eeeeee;
}

.loading-table-row.even {
  background-color: #ffffff;
}

.loading-table-header-cell-fixed,
.loading-table-row-cell-fixed {
  width: 30px;
}

.loading-table-header-cell,
.loading-table-row-cell {
  /* width: calc(100% / 5); */
  width: 150px;
  padding: 10px 0 0 10px;
}

.loading-table-header-cell:last-child,
.loading-table-row-cell:last-child {
}

.loading-table-header-cell {
  border-left: 1px solid #565556;
}

.loading-table-row-cell {
  border-left: 1px solid #eeeeee;
}

.first-letter-image {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 24px;
  vertical-align: middle;
  text-align: center;
  border-radius: 5px;
  background-color: rgb(230, 230, 230);
  color: rgb(192, 192, 192);
  border: 1px solid rgb(192, 192, 192);
}

.image-preview-large {
  width: 120px;
  height: 120px;
  line-height: 120px;
}

@media screen and (max-width: 990px) {
  .scrollable-table,
  .loading-table {
    overflow: hidden;
  }
}

@media (hover: hover) {
  .body-row:hover .body-row-back {
    background: #d6d6d6;
  }

  .body-row:hover .body-cell:after {
    background: #c0c0c0;
  }

  .body-row.body-row-selected:hover .body-row-back {
    background: #88c5ee;
  }

  .body-row.body-row-selected:hover .body-cell:after {
    background: #2196f3;
  }
}

/*
z-index value must be > 1500 (dialog's z-index).
Overriding Lightbox's z-index (not able to do it via props).
*/
.ReactModal__Overlay {
  z-index: 1501 !important;
}

.notifications-table {
  height: calc(100% - 70px);
  min-height: calc(100% - 70px);
}
.notifications-container {
  height: calc(100% - 31px);
  min-height: calc(100% - 31px);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.basic-info-settings-record-selector {
  position: relative;
  max-width: 360px;
  margin-top: 20px;
}

.email-list {
  margin-top: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.email-list-item {
  padding-left: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  position: relative;
  min-height: 60px;
  background-color: #eee;
  margin: 10px 0;
}
.popup-email-input-wrapper {
  padding: 10px;
}

.alert-item-container {
  padding: 15px;
}

.alerts-list-item {
  padding-left: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
  min-height: 76px;
  background-color: #eee;
  margin: 10px 0;
}

.alerts-list-item-with-error {
  height: 96px;
  background-color: rgba(255, 145, 145, 0.38);
}

.alert-list-input-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.alert-pattern-selector {
  padding: 10px 15px;
}
.alert-text-field-container{
  width: 120px;
  padding-right: 15px;
  margin-top: -8px;
}
.alert-time-title {
  padding-top: 25px;
}
.alert-error-title {
  padding-top: 25px;
  color: #f15a29;
}

.basic-info-container{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.available-rule-fields {
  max-height: 360px;
  overflow-y: auto;
}

.base-info-selector {
  position: relative;
  max-width: 360px;
  margin-top: 30px;
}

.login-start-logo {
  width: 250px;
  height: 105px;
  margin: 30px auto 50px auto;
  background: url(/images/Login-FullLogo.png) no-repeat center;
  background-size: 250px auto;
}

@media
(-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {
  .login-start-logo {
    background: url(/images/Login-FullLogo@2x.png) no-repeat center;
    background-size: 250px auto;
  }
}

@media screen and (max-width: 479px) {
  .login-start-logo {
    width: 200px;
    background-size: 200px auto;
    height: 85px;
    margin-bottom: 30px;
    margin-top: 10px;
  }
}
/* react-cropper */
.cropper-circle .cropper-view-box {
  border-radius: 50%;
}

.cropper-point {
  border-radius: 50%;
}

/* react-file-drop */
.file-drop
  > .file-drop-target.file-drop-dragging-over-target
  .avatar-uploader-dialog {
  background-color: rgba(4, 134, 220, 0.05);
  color: #0486dc;
}

.avatar-uploader-dialog {
  margin: 0;
  padding: 50px 0px;
  background-color: #f1f1f1;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;
}

.avatar-uploader-dialog:hover {
  background-color: rgba(4, 134, 220, 0.05);
  color: #0486dc;
}

.reset-password {
  min-height: 215px;
}
.reset-password h1 {
  font-size: 26px!important;
  font-weight: 500!important;
}
.badge {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.badge .fa {
  position: relative;
  top: -1px;
}
.verification-dialog .wizard-steps {
  max-width: 250px;
  margin: 30px auto !important;
}

.verification-dialog-target {
  font-size: 1.1em;
  margin: 30px 0;
  word-wrap: break-word;
}

.verification-dialog-submit-button {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.verification-dialog-body {
  margin: 0 auto;
  padding: 0 30px;
}

.verification-dialog-actions .btn {
  margin: 0 10px;
}

.verification-dialog-body form {
  margin: 0 auto;
}

.verification-dialog-footer {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 20px;
}

.verification-code-timer {
  height: 30px;
  width: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.verification-dialog-footer > div {
  padding: 20px 30px;
}

.verification-dialog-footer .right {
  margin-left: auto;
}

.verification-dialog-footer .left {
  text-align: left;
  font-size: 14px;
}

.verification-dialog-footer .left p {
  margin: 2px 0;
}

@media screen and (max-width: 767px) {
  .verification-dialog-actions .btn {
    display: block !important;
    max-width: 200px;
    margin: 20px auto;
  }

  .verification-dialog-footer {
    display: block;
  }

  .verification-dialog-footer .left,
  .verification-dialog-footer .right {
    width: 100%;
    text-align: center;
  }

  .verification-dialog-target {
    font-size: 1.1em;
  }
}

.page_profile-settings .card {
  margin-top: 0px!important;
}

.page_profile-settings .card:last-of-type {
  margin-bottom: 0px!important;
}

.page_profile-settings .avatar-preview > div:first-child {
  margin: 0px 10px 0px 0px!important;
  display: inline-block!important;
  vertical-align: middle;
}

.page_profile-settings form {
  width: 50%;
}

.page_profile-settings .btn {
  margin-top: 20px;
}

@media screen and (max-width: 767px) {
  .page_profile-settings form {
    width: 100%;
  }
}
.page_account-apikey .card {
  margin-top: 0px !important;
}

.page_account-apikey .btn {
  margin-top: 20px;
}

.apikey-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background-color: #f7f6f6;
  padding: 10px 0 10px 15px;
  margin-bottom: 10px;
}

.apikey-item:last-of-type {
  margin-bottom: 0;
}

.apikey-item-name {
  font-size: 16px;
}

.apikey-item-options {
  font-size: 12px;
}

.apikey-item-actions {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-left: 5px;
}

.apikey-confirm {
  text-align: center;
  padding: 20px 0 10px 0;
}

.apikey-confirm-name,
.apikey-confirm-key {
  font-size: 18px;
}

.apikey-confirm-key {
  margin: 10px 0;
  min-height: 50px;
  padding: 14px 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
  background-color: #03bf98;
  color: #fff;
  word-wrap: break-word;
}

.apikey-confirm-descr {
  margin-bottom: 30px;
}

.page_account-settings .card {
  margin-top: 0px !important;
}

.page_account-settings form {
  width: 50%;
}

.page_account-settings .avatar-preview {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.page_account-settings .avatar-preview > div {
  margin-right: 10px;
}

.page_account-settings .avatar-preview .btn {
  margin-top: 0;
}

.page_account-settings .btn {
  margin-top: 20px;
}

.page_account-settings-dialog {
  padding: 20px 24px;
  text-align: left;
}

.page_account-settings-message {
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .page_account-settings form {
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .page_account-settings .avatar-preview .btn {
    margin-top: 20px;
  }
}

.slate-view {
  outline: none;
}

.slate-view .inner-page-title {
  padding: 20px 0;
}

.slate-view-container {
  /*72px - inner-page-header height*/
  height: calc(100% - 80px);
}

.slate-view-type {
  margin-left: 10px;
}

.slate-view-counter {
  padding: 0 9px;
  height: 23px;
  line-height: 23px;
  font-size: 13px;
  border-radius: 12px;
  margin-left: 10px;
/*---- global font setting ----*/
  font-weight: 400;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
}

.slate-view-actions {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.slate-view-actions-refresh {
  margin-right: 5px;
}

.slate-view-actions-copy-table {
  margin-left: 5px;
}

.slate-view-actions-divider {
  height: 34px;
  width: 1px;
  border-right: 1px solid #eee;
}

.slate-view-dropdown {
  padding: 0 5px;
  height: 100%;
  line-height: 30px;
  margin-left: -1px;
  cursor: pointer;
}

.slate-view-popover-item {
  padding: 10px 15px;
}

.slate-view-dropdown:hover {
  border-bottom-right-radius: 4px;
}

.slate-view-dropdown:hover,
.slate-view-dropdown-active {
  background: #ffffff;
  border-top-right-radius: 4px;
  color: #414042 !important;
  box-shadow: rgba(65, 64, 66, 0.12) 0 1px 6px,
    rgba(65, 64, 66, 0.12) 0 1px 4px;
  cursor: pointer;
}

.slate-view-popover {
  border-top-right-radius: 0 !important;
}

.slate-view-share-dialog {
  padding: 20px 24px;
  text-align: center;
}

.slate-view-share-dialog-url {
  padding: 20px 90px;
  text-align: center;
  font-weight: bolder;
  font-size: 18px;
  word-wrap: break-word;
}

.slate-view-share-dialog-url input{
  text-align: center;
  font-weight: bolder;
  font-size: 18px;
}

.slate-view-settings-dialog {
  padding: 20px 24px;
  text-align: left;
}

.slate-view-settings-dialog-btn {
  margin-right: 10px;
}

.slate-list-item {
  display: block;
  padding: 10px 25px;
  text-align: left;
}

.slate-view-create-button {
  position: absolute;
  top: 12px;
  right: 58px;
}

.slate-view-selector-container {
  display: -webkit-flex;
  display: flex;
}

.slate-view-list {
  width: 100%;
}

.slate-view-list-title {
  font-size: 20px;
  font-weight: 500;
  padding: 15px 25px;
  border-bottom: 1px solid #eeeeee;
  text-align: left;
}

.slate-view-list-title .fa {
  margin-right: 10px;
}

.slate-view-list-container {
  padding: 0 10px;
  max-height: 320px;
  overflow-y: auto;
  border-right: 1px solid #eeeeee;
}

.slate-view-list-item {
  padding: 10px 0;
  border-bottom: 1px solid #eeeeee;
}

.slate-view-list-item-button {
  position: relative;
  display: -webkit-flex;
  display: flex;
  padding: 0 52px 0 15px;
  -webkit-align-items: center;
          align-items: center;
}

.slate-view-list-item-name {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.slate-view-list-count {
  height: 23px;
  line-height: 23px;
  position: absolute;
  top: 50%;
  right: 10px;
  border-radius: 12px;
  font-size: 13px;
/*---- global font setting ----*/
  font-weight: 400;
  color: white;
  padding: 0 9px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.slate-view-empty {
  font-size: 16px;
  margin-top: 20px;
}

.slate-view-empty-create-button {
  margin-top: 20px;
}

.slate-view-inner {
  height: 100%;
  position: relative;
}

.slate-view-inner-expanded {
  position: absolute !important;
  top: 2px;
  bottom: 2px;
  left: 2px;
  right: 2px;
  height: calc(100% - 4px) !important;
  transition: all 0.3s;
  z-index: 5;
}

.slate-view-data {
  height: 100%;
  background: #f7f6f6;
  -webkit-touch-callout: none;
  position: relative;
}

.slate-view-data-expanded {
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100% !important;
}

.slate-view-data-expanded:before {
  display: block;
  content: '';
  position: fixed;
  top: 60px;
  right: 0;
  bottom: 0;
  left: 0;
  background: #f7f6f6;
}

@media screen and (min-width: 991px) {
  .slate-view-data-with-pane {
    width: calc(100% - 62px);
  }

  .slate-view-data-docked {
    width: calc(100% - 410px);
  }

  .slate-view-data-expanded.slate-view-data-docked {
    width: calc(100% - 405px);
  }
}

@media screen and (max-width: 990px) {
  .slate-view-inner {
    overflow: hidden;
  }
}

@media screen and (max-width: 500px) {
  .slate-view-selector-container {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
}

/* for safari */
.gmnoprint map area {
  display: block;
  cursor: pointer !important;
}

/* react-google-maps infoBox: can't redefine inline styles */
.map-info-top {
  -webkit-transform: translate3d(-50%, -138px, 0) !important;
          transform: translate3d(-50%, -138px, 0) !important;
}

.map-info-bottom {
  -webkit-transform: translate3d(-50%, 25px, 0) !important;
          transform: translate3d(-50%, 25px, 0) !important;
}

.map-info-window {
  height: 70px;
  padding: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  background-color: #fff;
  white-space: nowrap;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.map-info-window-image {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-right: 15px;
  background-size: cover;
  border-radius: 5px;
  font-size: 20px;
  text-align: center;
}

.map-info-window-title {
  font-size: 16px;
 /*---- global font setting ----*/
  font-weight: 400;
  white-space: nowrap;
}

.map-info-window-arrow-top,
.map-info-window-arrow-bottom {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
}

.map-info-window-arrow-top {
  bottom: -25px;
  border-top: 25px solid #ccc;
}

.map-info-window-arrow-bottom {
  bottom: 69px;
  border-bottom: 25px solid #ccc;
}

.map-info-window-arrow-top:after,
.map-info-window-arrow-bottom:after {
  content: "";
  display: block;
  position: absolute;
  right: 50%;
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
}

.map-info-window-arrow-top:after {
  bottom: 2px;
  border-top: 25px solid #fff;
}

.map-info-window-arrow-bottom:after {
  bottom: -27px;
  border-bottom: 25px solid #fff;
}

.map-view {
  height: 100%;
}

.map-view-header {
  position: relative;
  height: 36px;
  z-index: 30;
}

.map-view-header-checkbox {
  width: 250px;
  height: 36px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-left: 7px;
}

.slate-pane {
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 1002;
  box-shadow: -6px 0px 6px rgba(0, 0, 0, 0.15);
  transition: all ease 0.3s;
  background-color: #eeeeee;
}
.slate-full-page-container {
  height: 100%;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0;
}
.slate-full-page {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 2;
}

.slate-full-page-wrap {
  overflow: hidden;
  width: 100%;
  height: calc(100% - 36px);
  background-color: #001b38;
}

.slate-full-page-for-not-pane-wrap {
  height: 100%;
}

.spinner {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.spinner::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url(/images/loader.png) #001b38 no-repeat center center;
  -webkit-animation-name: rotate;
  -webkit-animation-duration:1s;
  -webkit-animation-iteration-count:infinite;
  -webkit-animation-timing-function:linear;
  -moz-animation-name: rotate;
  -moz-animation-duration:1s;
  -moz-animation-iteration-count:infinite;
  -moz-animation-timing-function:linear;
}

@-webkit-keyframes rotate {
  from {-webkit-transform:rotate(0deg);}
  to {  -webkit-transform:rotate(360deg);}
}

.slate-full-page-for-not-pane {
  max-height: 1020px;
  max-width: 620px;
}
.slate-pane.slate-pane-docked,
.slate-pane.slate-pane-active {
  transition: all ease 0.3s;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}

.slate-pane.slate-pane-hidden {
  transition: all ease 0.3s;
  box-shadow: none;
}

.slate-pane-bar {
  height: 36px;
  line-height: 36px;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  position: relative;
  z-index: 30;
  visibility: hidden;
}
.content-fixed .slate-pane-bar {
  visibility: visible;
}

.slate-pane-bar-actions,
.slate-pane-bar-actions-right {
  display: -webkit-flex;
  display: flex;
}

.slate-pane-bar-actions {
  width: 100%;
}

.slate-pane-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  background: rgba(0, 0, 0, 0.2);
  display: none;
  transition: all 0.4s;
}

.slate-pane-wrapper {
  width: 100%;
  height: calc(100% - 36px);
  position: relative;
  overflow: hidden;
}

.slate-pane-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-transform: translate3d(0px, 0px, 0px);
          transform: translate3d(0px, 0px, 0px);
  -webkit-overflow-scrolling: touch;
  top: 0;
}

.slate-pane-container {
  position: absolute;
  width: 100%;
  -webkit-transform: translate3d(0px, 0px, 0px);
          transform: translate3d(0px, 0px, 0px);
}

.slate-pane-content {
  position: absolute;
  will-change: transform;
  width: 100%;
  top: 300px;
  -webkit-transform: translate3d(0px, 0px, 0px);
          transform: translate3d(0px, 0px, 0px);
  background: #eeeeee;
}

.slate-pane-header-fixed {
  display: none;
  -webkit-transform: translate3d(0, 0, 0);
}

.slate-pane-header-fixed.visible {
  display: block;
  position: absolute;
  top: -1px;
  width: 100%;
  height: 75px;
  z-index: 40;
  overflow: hidden;
}
.slate-pane-header-fixed.visible:after {
  box-shadow: inset 0 85px 30px -50px rgba(53, 53, 53, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
  display: block;
  background-color: #414042;
}

.slate-pane-header {
  position: absolute;
  will-change: transform;
  width: 100%;
  height: 600px;
  top: -300px;
  -webkit-transform: translate3d(0px, 0px, 0px);
          transform: translate3d(0px, 0px, 0px);
  overflow: hidden;
  z-index: 16;
}

.slate-pane-cover {
  height: 600px;
  width: 100%;
  overflow: hidden;
}

.slate-pane-header.hidden,
.slate-pane-cover.hidden {
  display: none;
}

.slate-pane-cover-image {
  width: 100%;
  background-size: cover;
  background-position: 50% calc(50% - 50px);
  background-repeat: no-repeat;
}

.slate-pane-header-fixed-image,
.slate-pane-header-image {
  height: 600px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform;
  -webkit-transform: translate3d(0px, 0px, 0px);
          transform: translate3d(0px, 0px, 0px);
  background-position: 50% 100%;
  background-repeat: no-repeat;
}

.slate-pane-header-fixed-image > div,
.slate-pane-header-image > div {
  width: 100%;
  height: 400px;
  background-size: cover;
  background-position: 50% calc(50% - 50px);
  position: absolute;
  top: 300px;
  -webkit-transform-origin: center top;
          transform-origin: center top;
  background-repeat: no-repeat;
}

.slate-pane-header-fixed-image > div {
  top: 2px;
}

.slate-pane-header-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 70px;
  box-shadow: inset 0 -85px 30px -50px rgba(53, 53, 53, 0.5);
  color: #ffffff;
  padding: 5px 15px;
  z-index: 16;
}

.slate-pane-header-fixed-image {
  background-position: 50% calc(50% - 148px);
  top: -1px;
}

.slate-pane-header-top-shadow {
  position: absolute;
  top: -1px;
  width: 100%;
  height: 75px;
  box-shadow: inset 0 85px 30px -50px rgba(53, 53, 53, 0.5);
  z-index: 15;
}

.slate-pane-header-top-shadow.hidden {
  display: none;
}

.slate-title {
  height: 30px;
  line-height: 30px;
  font-size: 24px;
  width: calc(100% - 60px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.slate-account-name {
  font-size: 12px;
  position: relative;
  top: 2px;
}

.slate-pane-header-bottom {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.slate-pane-actions {
  position: relative;
  right: -10px;
}

.slate-pane-actions > div {
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 18px;
}

.slate-pane-wrapper .company-logo {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 16;
  opacity: 1;
  transition: opacity 0.1s;
  -webkit-transform: translate3d(0px, 0px, 0px);
          transform: translate3d(0px, 0px, 0px);
}

.slate-pane-wrapper .company-logo.hidden {
  opacity: 0;
  transition: opacity 0.1s;
}

.slate-pane-do-actions {
  position: absolute;
  right: 5px;
  top: 10px;
  z-index: 1000;
  color: #ffffff;
  font-size: 18px;
  -webkit-transform: translate3d(0px, 0px, 0px);
          transform: translate3d(0px, 0px, 0px);
}

.slate-pane-do-action {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 18px;
  display: inline-block;
}

.slate-pane-without-cover .slate-pane-content {
  top: 75px;
}

.slate-pane-header-fixed {
  background-color: #414042;
}

.slate-pane-chevron {
  display: block;
  width: 60px;
  height: 30px;
  background: url(/images/chevron.png) no-repeat;
  background-size: 60px auto;
  background-position: center;
  position: absolute;
  top: 10px;
  left: calc(50% - 30px);
  z-index: 16;
  opacity: 1;
  transition: opacity 0.1s;
  -webkit-transform: translate3d(0px, 0px, 0px);
          transform: translate3d(0px, 0px, 0px);
}

.slate-pane-icon {
  /* color: #696869; */
  color: #ffffff;
  opacity: 0.6;
  transition: all 0.2s;
  display: inline-block;
  cursor: pointer;
  width: 36px;
}

.slate-pane-pin {
  display: none;
}
.slate-pane-close {
  display: inline-block;
}

.slate-pane-prev,
.slate-pane-next {
  font-size: 22px;
}

.slate-pane-icon-disabled {
  opacity: 0.3;
}

.slate-pane-icon:not(.slate-pane-icon-disabled):hover {
  opacity: 1;
}

.slate-pane {
  overflow: hidden;
  width: 100%;
  box-shadow: -6px 0px 6px rgba(0, 0, 0, 0.15);
  -webkit-transform: translateY(105%);
          transform: translateY(105%);
}

.slate-pane-dialog {
  position: relative;
  min-height: 370px;
  max-height: 70vh;
  overflow-y: auto;
  padding: 5px 0;
  background-color: #eee;
  font-size: 14px;
}

.slate-pane-dialog-empty {
  font-size: 16px;
  padding-top: 20px;
}

.slate-pane-powered-container {
  padding: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.slate-pane-powered-text {
  margin-right: 6px;
  font-size: 12px;
  font-weight: 500;
  font-style: italic;
  color: #414042;
}

.slate-pane-powered-img {
  width: 100px;
}

@media screen and (max-width: 480px) {
  .slate-pane {
    width: 100%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
}

@media screen and (min-width: 600px) {
  .slate-full-page-for-not-pane-wrap {
    height: 100%;
    max-height: 1020px;
    max-width: 620px;
    box-shadow: 3px 2px 16px 0px #000000;
    border-radius: 15px;
    border: 5px solid #001b38;
  }
  .slate-full-page-container {
    padding: 15px;
  }
}

@media screen and (min-height: 1000px) {
  .slate-full-page-container {
    padding: 15px;
  }
}

@media screen and (max-width: 990px)  {
  .slate-pane-chevron.hidden {
    opacity: 0;
    transition: opacity 0.1s;
  }
}

@media screen and (min-width: 991px) {
  .slate-pane-pin {
    display: inline-block;
  }

  .slate-pane-close {
    display: none;
  }

  .slate-pane {
    width: 400px;
    -webkit-transform: translate(460px);
            transform: translate(460px);
    transition: none;
  }

  .slate-pane slate-full-page-wrap {
    width: 100%;
    height: calc(100% - 36px);
  }

  .slate-pane.slate-pane-active {
    -webkit-transform: translate(0px);
            transform: translate(0px);
  }

  .slate-pane.slate-pane-hidden {
    -webkit-transform: translate(350px);
            transform: translate(350px);
    opacity: 0.6;
  }

  .slate-pane.slate-pane-hidden:hover {
    opacity: 1;
  }

  .slate-pane.slate-pane-docked {
    -webkit-transform: translate(0px);
            transform: translate(0px);
    transition: none;
    box-shadow: none;
  }

  .slate-pane.slate-pane-docked .slate-pane-pin {
    color: #f15a29;
    opacity: 1;
  }

  .slate-pane-inner-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;
    z-index: 50;
  }
  .slate-pane-overlay {
    display: block;
  }
  .slate-pane-chevron {
    display: none;
  }
}


.slate-page {
  /* width: 96%; */
  height: 100%;
  /* max-width: 600px; */
  /* margin: 0 auto; */
}

.slate-page .slate-pane-wrapper {
  height: 100%;
}

.slate-page .slate-pane-container {
  position: relative;
  max-width: 600px;
  margin: 0 auto;
}

.slate-page .slate-pane-header-fixed,
.slate-page .slate-pane-header-top-shadow,
.slate-page .slate-pane-cover {
  max-width: 600px;
}

.slate-page .slate-pane-cover {
  position: relative;
}

.breadcrumbs {
  padding: 15px 0px 0px 0px;
}
.breadcrumbs-item,
.breadcrumbs-item a,
.breadcrumbs-item .breadcrumbs-chevron {
  display: inline-block;
}

.breadcrumbs-item .breadcrumbs-chevron {
  margin: 0px 10px;
}


.layout-sign .sign-card-wrapper .legal-info {
  display: none;
}

.layout-sign .left-pane .legal-info {
  display: block;
  position: absolute;
  left: 2.15em;
  bottom: 0.7em;
}

.layout-sign .left-pane .legal-info {
  color: #c7c7c7;
}

@media screen and (max-width: 767px) {
  .layout-sign .left-pane .legal-info {
    display: none;
  }

  .layout-sign .sign-card-wrapper .legal-info {
    display: block;
    border: 0;
    margin-top: 0;
  }
}

.legal-info {
  font-size: 10px;
  transition: all 0.4s;
}

.legal-info a {
  color: #c7c7c7;
}

.legal-info > div {
  margin: 5px 0;
}

.legal-info > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.legal-info > div > span {
  display: inline-block;
  border-right: 1px solid #c7c7c7;
  padding: 0 5px;
/*---- global font setting ----*/
  font-weight: 400;
}

.legal-info > div > span:last-child {
  border-right: 0;
}

.legal-info .footer-env {
  font-weight: 600;
}

.account-dialog {
  min-height: 290px;
}

.account-dialog-left {
  width: 230px;
  position: absolute;
  left: 0;
  top: 60px;
}

.account-dialog-right {
  min-height: 260px;
  width: calc(100% - 230px);
  margin-left: 230px;
}

.account-add {
  padding: 35px 25px;
}

.account-add-title {
  font-size: 22px;
  margin-bottom: 15px;
}

.account-add-desc {
  font-size: 12px;
}

.account-add form {
  max-width: 170px;
  margin: 20px auto 0px auto;
}

.account-add form .btn {
  margin-top: 10px;
}

.account-list-container {
  padding: 0px 15px;
}

.account-list-search {
  background: url('/public/images/search_default.png') no-repeat;
  background-size: 16px;
  background-position: 15px 15px;
}

.account-list-search input {
  width: calc(100% - 27px) !important;
}

.account-item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 10px 0px;
}

.account-item-image {
  display: inline-block;
  width: 44px;
  height: 44px;
  border-radius: 5px;
  margin-right: 10px;
  background-size: 44px;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  line-height: 42px;
  font-size: 20px;
}

.account-add .form-error {
  font-size: 12px !important;
  margin-top: 10px;
}

@media screen and (min-width: 992px) {
  .account-list {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -webkit-box-sizing: border-box;
  }
}

@media screen and (min-width: 601px) {
  .account-list-container {
    max-height: 360px;
  }

  .account-list {
    overflow-y: auto;
    -webkit-box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
  }
}

@media screen and (max-width: 600px) {
  .account-list-container {
    max-height: 260px;
    display: inline-block;
    width: 100%;
    overflow-y: auto;
  }

  .account-dialog {
    /*overflow-y: auto;*/
    -webkit-box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
  }

  .account-dialog-left {
    position: relative;
    top: auto;
    width: 100%;
  }

  .account-add {
    padding: 20px;
    border-bottom: 1px solid #eeeeee;
  }

  .account-add form {
    margin-top: 0;
  }

  .account-add-title {
    margin-bottom: 5px;
  }

  .account-dialog-right {
    margin-left: 0;
    width: 100%;
    border: 0 !important;
  }
}

.sidebar {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  overflow-x: hidden;
  width: 250px;
  color: white;
  z-index: 1001;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-perspective: 1000;
          perspective: 1000;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  transition: all 0.35s;
  overflow: hidden;
}

.sidebar .legal-info {
  padding: 15px 6px;
}

.sidebar-content {
  height: calc(100% - 60px);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.sidebar-header {
  line-height: 60px;
  font-size: 23px;
  padding: 0px 15px;
  position: relative;
}

.sidebar-header-title {
  width: 190px;
}

.sidebar-header i {
  font-size: 14px;
  position: absolute;
  right: 15px;
  top: 25px;
  opacity: 0.6;
  cursor: pointer;
}

.sidebar-header i:hover {
  opacity: 1;
}

.sidebar-logout {
  margin: 15px;
}

.sidebar-logo {
  background: url("/images/Login-SL8-Logo@2x.png") no-repeat center left;
  background-size: 120px auto;
  width: 120px;
  height: 60px;
  margin-left: 5px;
}

.sidebar-nav {
  list-style-type: none;
  margin: 0;
  padding: 0px;
  width: 250px;
}

.sidebar-nav-cont {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.sidebar-nav-icon {
  width: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-right: 15px;
}

.sidebar-nav-item {
  position: relative;
  cursor: pointer;
}

.sidebar-nav-item:after {
  display: block;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  transition: all 0.4s;
}

.sidebar-nav-item:hover:after {
  width: 100%;
  right: 0;
  transition: all 0.4s;
}

.sidebar-nav-link {
  position: relative;
  display: block;
  padding: 10px 20px;
  z-index: 1;
}
.sidebar-nav-link:hover {
  text-decoration: none;
}

.sidebar-nav ul {
  list-style-type: none;
  padding-left: 0px;
}
.sidebar-nav ul li {
  padding-left: 30px;
}

.sidebar-nav-link .fa {
  font-size: 20px;
}

.sidebar-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1001;
  background-color: transparent;
}

.collapse-icon {
  position: absolute;
  width: 40px;
  height: 60px;
  right: 0;
  top: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.2s;
  color: #f15a29;
}

.sidebar-account {
  position: relative;
  background-color: #39383a;
  transition: all 0.2s;
}

.sidebar-account-inner {
  padding: 18px;
}

.sidebar-account:hover {
  cursor: pointer;
  background-color: #565556;
}

.sidebar-account-image {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  margin: 0px auto;
  transition: all 0.2s;
  background-size: 80px;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  line-height: 78px;
  font-size: 40px;
}

.sidebar-account-name {
  text-align: center;
  max-width: 200px;
  margin: 15px auto 0px auto;
  max-height: 100px;
  height: auto;
  transition: all 0.2s;
  opacity: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: normal;
}

.sidebar-account-icon {
  position: absolute;
  width: 40px;
  height: 44px;
  top: 5px;
  right: 0px;
  opacity: 0.6;
  transition: all 0.2s;
  display: block;
  cursor: pointer;
  text-align: center;
}

.sidebar-account-icon .fa {
  line-height: 44px;
  cursor: pointer;
}

.sidebar-account-icon:hover {
  opacity: 0.8;
  transition: all 0.2s;
}

@media screen and (min-width: 992px) {
  .sidebar-overlay-inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0);
    z-index: 9;
  }

  .sidebar-collapsed .sidebar {
    width: 60px;
    transition: all 0.35s;
  }

  .sidebar-collapsed .sidebar.hover {
    width: 250px;
    transition: all 0.35s;
  }

  .sidebar-collapsed .sidebar-header .collapse-icon {
    opacity: 0;
    color: #fff;
  }
  .sidebar-collapsed .sidebar.hover .sidebar-header .collapse-icon {
    opacity: 0.6;
  }
  .sidebar-collapsed .sidebar.hover .sidebar-header .collapse-icon:hover {
    opacity: 1;
  }

  .sidebar-collapsed .sidebar .sidebar-logo {
    width: 25px;
    transition: all 0.3s;
  }

  .sidebar-collapsed .sidebar .sidebar-nav ul li {
    overflow: hidden;
    padding-left: 0px;
    transition: all 0.3s;
  }

  .sidebar-collapsed .sidebar .sidebar-nav-title {
    opacity: 0;
    transition: all 0.3s;
  }

  .sidebar-collapsed .sidebar.hover .sidebar-logo {
    width: 120px;
    transition: all 0.3s;
  }

  .sidebar-collapsed .sidebar.hover .sidebar-nav ul li {
    padding-left: 30px;
    transition: all 0.3s;
  }

  /* For Explorer and Edge */
  @supports (-ms-ime-align: auto) {
    .sidebar-collapsed .sidebar:hover .sidebar-nav ul li {
      padding-left: 30px;
      transition: all 0.3s;
    }
  }

  .sidebar-collapsed .sidebar.hover .sidebar-nav-title {
    opacity: 1;
    transition: all 0.3s;
  }

  .sidebar-collapsed .sidebar-account-image {
    width: 24px;
    height: 24px;
    transition: all 0.2s;
    background-size: 24px;
    line-height: 24px;
    font-size: 16px;
  }

  .sidebar-collapsed .sidebar-account-name {
    max-width: 0px;
    overflow: hidden;
    transition: all 0.2s;
    max-height: 0;
    margin-top: 0;
    opacity: 0;
  }

  .sidebar-collapsed .sidebar-account-icon {
    opacity: 0;
    transition: all 0.2s;
  }

  .sidebar-collapsed .sidebar.hover .sidebar-account-image {
    width: 80px;
    height: 80px;
    transition: all 0.2s;
    background-size: 80px;
    line-height: 78px;
    font-size: 40px;
  }

  .sidebar-collapsed .sidebar.hover .sidebar-account-name {
    margin-top: 15px;
    max-width: 200px;
    max-height: 100px;
    height: auto;
    transition: all 0.2s;
    opacity: 1;
  }

  .sidebar-collapsed .sidebar.hover .sidebar-account-icon {
    opacity: 0.6;
    transition: all 0.2s;
  }

  .sidebar-collapsed .sidebar.hover .sidebar-account-icon:hover {
    opacity: 0.8;
  }

  .sidebar-collapsed .sidebar a .fa {
    pointer-events: none;
  }

  .sidebar-collapsed .sidebar.hover a .fa {
    pointer-events: initial;
  }

  .sidebar-collapsed .legal-info {
    opacity: 0;
  }

  .sidebar-collapsed .sidebar.hover .legal-info {
    opacity: 1;
  }
}

@media screen and (max-width: 991px) {
  .sidebar-header .collapse-icon {
    display: none;
  }

  .sidebar {
    width: 0px;
    transition: all 0.35s;
  }

  .sidebar-opened .sidebar {
    width: 250px;
    transition: all 0.35s;
  }

  .sidebar-account {
    width: 250px;
  }
}

.topbar {
  position: absolute;
  left: 0;
  top: 0;
  height: 60px;
  border-bottom: 1px solid;
  background-color: #ffffff;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
@media screen and (min-width: 992px) {
  .topbar {
    margin-left: 250px;
    width: calc(100% - 250px);
  }
  .sidebar-collapsed .topbar {
    margin-left: 60px;
    width: calc(100% - 60px);
  }
}
.profile-wrapper {
  display: inline-block;
  max-width: 340px;
  display: inline-block;
  float: right;
}
.topbar-user-profile {
  height: 60px;
  margin-left: 10px;
  padding: 0 10px;
  margin-right: 15px;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
  background: rgba(255, 255, 255, 0);
  transition: all 0.2s;
}

.topbar-search {
  display: inline-block;
  /* width: calc(100% - 350px); */
  width: 246px;
  margin-right: 25px;
  margin-left: 29px;
  position: relative;
  top: -5px;
}

.sidebar-collapsed .topbar-search {
  /* width: calc(100% - 358px); */
  width: 246px;
}
.inline {
  display: inline-block;
}

@media screen and (max-width: 991px) {
  .topbar-search {
    width: calc(100% - 443px);
  }
  .topbar-search {
    display: none;
  }
  .topbar-search.toggled {
    display: inline-block;
    position: absolute;
    width: calc(100% - 146px);
    background-color: #eeeeee;
    height: 100%;
    margin-right: 0;
    margin-left: 0px;
    z-index: 2;
  }
}

.topbar-user-profile:hover,
.topbar-user-profile.open {
  background: rgba(255, 255, 255, 0.3);
  transition: all 0.2s;
}

.topbar-user-profile .fa {
  font-size: 18px;
  padding-left: 10px;
}

.topbar-user-name {
  font-size: 12px;
  padding-right: 15px;
}
@media screen and (max-width: 475px) {
  .topbar-user-name {
    display: none;
  }
}

.topbar-toggle {
  margin: 9px 6px 3px 6px !important;
}

.topbar-inner {
  height: 100%;
}

.topbar-dialog {
  padding-top: 0px !important;
}

.popover {
  width: 170px;
}

.popover-item {
  padding: 10px;
  color: rgba(0, 0, 0, 0.7);
  text-decoration: none;
  cursor: pointer;
  display: block;
  min-width: 100px;
  position: relative;
}

.popover-item:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 0;
  transition: all 0.2s;
  background: rgba(0, 0, 0, 0.07);
}

.popover-item:hover {
  color: rgba(0, 0, 0, 1);
  text-decoration: none;
}

.popover-item:hover:after {
  right: 0;
  width: 100%;
  transition: all 0.2s;
}

.avatar-default {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  background-size: cover;
}

.dialog-error {
  z-index: 9999;
}
.dialog-error-title {
  background-image: url(/images/notification-sad.png);
  background-repeat: no-repeat;
  background-size: 32px 32px;
  background-position: 20px center;
  padding: 25px 25px 25px 70px;
  font-size: 24px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

}

.dialog-error-dismiss {
  position: absolute;
  right: 15px;
  top: 5px;
  font-size: 40px;
  line-height: 40px;
  cursor: pointer;
}

.dialog-error-body {
  padding: 100px 25px 25px 25px;
}

.dialog-error h1 {
  font-size: 24px;
  /*---- global font setting ----*/
  font-weight: 400;
  margin-top: 0;
}

.dialog-error-body-text {
  line-height: 1.4em;
  margin-bottom: 30px;
}

.dialog-error-done {
  text-align: right;
  margin-top: 20px;
}

.dialog-error-action {
  text-align: center;
  padding: 15px 0;
}

.dialog-error-issue {
  display: inline-block;
  height: 42px;
  line-height: 42px;
  vertical-align: top;
  font-size: 20px;
/*---- global font setting ----*/
  font-weight: 400;
  width: calc(90% - 150px);
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.dialog-error-issue > div {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-right: -4px;
  padding: 0 15px;
}

.dialog-error-issue-hint {
  position: absolute;
  bottom: -15px;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 11px;
  line-height: 12px;
/*---- global font setting ----*/
  font-weight: 400;
}


@media screen and (max-width: 767px) {
  .dialog-error-title {
    font-size: 14px;
    background-size: 16px 16px;
    background-position: 10px center;
    padding: 15px 15px 15px 36px;
  }

  .dialog-error-dismiss {
    font-size: 24px;
    right: 10px;
  }

  .dialog-error-body {
    padding: 60px 15px 15px 15px;
  }

  .dialog-error-body h1 {
    font-size: 14px;
/*---- global font setting ----*/
    font-weight: 400;
  }

  .dialog-error-body-text {
    font-size: 14px;
  }

  .dialog-error-action > div,
  .dialog-error-action > a {
    display: block;
    width: 100%!important;
  }

  .dialog-error-issue {
    height: auto;
  }

  .dialog-error-issue > div:first-child {
    line-height: 1.2em;
    padding: 15px;
    font-size: 14px;
    border-radius: 4px;
  }

  .dialog-error-issue-hint {
    position: relative;
    bottom: auto;
    font-size: 11px;
    margin: 5px 0 15px 0;
  }

  .dialog-error-done {
    text-align: center;
    margin-bottom: 10px;
  }
}
body {
  color: #414042;
  background-color: #f7f6f6;
}

body.dialog-open,
body.slate-pane-open {
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #eee inset;
}

a, .link {
  color: #f15a29;
  text-decoration: none;
  cursor: pointer;
}

a:hover, .link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.btn {
  box-shadow: none!important;
}

.btn span {
  text-transform: inherit!important;
}

.dialog .btn-action {
  margin: 5px;
}

.text-center {
  text-align: center;
}

.text-small {
  font-size: 11px;
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-left: -20px;
}

@media screen and (max-width: 767px) {
  .layout-sign-right .footer {
    border-color: #d8d8d8;
    color: #ababab;
  }


  .footer,
  .footer a {
    color: #ababab;
  }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  select,
  textarea,
  input {
    font-size: 16px!important;
  }
}



* {
  box-sizing: border-box;
}

html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

body {
  margin: 0;
}


article,
aside,
footer,
header,
nav,
section {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

a {
  background-color: transparent; /* 1 */
  -webkit-text-decoration-skip: objects; /* 2 */
}

strong {
  font-weight: inherit;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

audio,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

button,
input { /* 1 */
  overflow: visible;
}

button,
select { /* 1 */
  text-transform: none;
}

button,
html [type="button"], /* 1 */
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; /* 2 */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

[hidden] {
  display: none;
}



body,
#root {
  /*position: relative;*/
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

body,
#root {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
/*---- global font setting ----*/
  font-weight: 400 !important;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  overflow-y: hidden;
}

.body {
  z-index: 1;
}

#root {
  z-index: 2;
  -webkit-overflow-scrolling: touch;
}

.app {
  width: 100%;
  height: 100%;
}

h1 {
  font-weight: 600;
  font-size: 1.8em;
}

.grid-header {
  overflow-x: hidden !important;
}

.app-env-topbar {
  position: absolute;
  top: 0;
  width: 100%;
  height: 6px;
  z-index: 2000;
}

.views,
.view {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 500;
}

.views {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.view {
  overflow: hidden;
  box-sizing: border-box;
}

.pages {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.page {
  box-sizing: border-box;
  position: absolute;
  right: 0;
  top: 60px;
  width: calc(100% - 250px);
  height: 100%;
  /*transition: width .25s;*/
}

.sidebar-collapsed .page {
  width: calc(100% - 60px);
  /*transition: width .25s;*/
}

.container {
  padding: 0 30px;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  height: calc(100% - 61px);
  position: relative;
  -webkit-overflow-scrolling: touch;
}
.container.no-padding-container {
  padding: 0;
}
.container.no-padding-container .inner-padding {
  padding: 0 30px;
}
.container.no-scroll-container {
  height: calc(100% - 32px);
  overflow-y: hidden;
}

.layout-dashboard .content {
  /* Footer desktop height - 61px*/
  min-height: calc(100% - 61px);
}

.layout-dashboard .content.content-fixed {
  height: calc(100% - 2px);
  min-height: 0;
}

.layout-dashboard .content > div {
  height: 100%;
}

/* .loading-logo {
  width: 51px;
  height: 44px;
  background-size: 51px 44px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -25px;
} */

.field-to-verify {
  position: relative;
}

.field-to-verify .btn-verify,
.field-to-verify .badge {
  width: 130px;
  text-align: center;
  position: absolute;
  right: -150px;
  top: -10px;
}

.field-to-verify .btn-verify-2FA,
.field-to-verify .badge-verified-second{
  width: 130px;
  text-align: center;
  position: absolute;
  right: -300px;
  /*margin-left: 24px;*/
  top: -10px;
}

.field-to-verify .btn-disable-2FA {
  width: 130px;
  text-align: center;
  position: absolute;
  right: -450px;
  /*margin-left: 24px;*/
  top: -10px;
}

.field-to-verify .badge {
  height: 34px;
  margin-top: 20px;
}

.form-error-action {
  display: none;
}

.pages.slate-pane-open .container {
  overflow: inherit;
}

.inner-page {
  /*31px - breadcrumbs height*/
  min-height: calc(100% - 31px);
  /* -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden; */
}

.content-fixed .inner-page {
  height: calc(100% - 31px);
}

.inner-page-header {
  display: -webkit-flex;
  display: flex;
}

.inner-page-title {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
}

.inner-page-title h1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
}

@media screen and (min-width: 992px) {
  .sidebar-collapsed .app-content {
    padding-left: 80px;
    transition: all 0.35s;
  }

  .pages.slate-pane-open {
    z-index: 500;
  }
}

@media screen and (max-width: 991px) {
  .sidebar-opened .page {
    overflow: hidden;
  }

  .sidebar-overlay {
    content: '';
    display: block;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .page,
  .sidebar-collapsed .page {
    width: 100%;
  }

  .sidebar-opened .page,
  .sidebar-opened .topbar {
    overflow: hidden;
    position: fixed;
    -webkit-transform: translateX(250px);
            transform: translateX(250px);
    transition: all 0.35s;
  }
}

.scrollbar-measure {
  width: 100px;
  height: 100px;
  overflow: scroll;
  position: absolute;
  top: -9999px;
}


@media screen and (max-width: 1280px) {
  .field-to-verify .btn-verify,
  .field-to-verify .badge {
    position: relative;
    right: 0;
    top: 0;
    margin-top: 0;
    margin-right: 12px;
  }

  .field-to-verify .btn-verify-2FA,
  .field-to-verify .badge-verified-second {
    position: relative;
    right: 0;
    top: 0;
    margin-top: 0;
  }

  .field-to-verify .btn-disable-2FA {
    position: relative;
    right: 0;
    top: 0;
    margin-top: 0;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 1.4em;
  }

  .container {
    padding: 0 10px;
  }

  .layout-dashboard .content {
    min-height: calc(100% - 79px);
  }

  .form-error-action {
    display: block;
    background: #f15a29;
    color: #ffffff;
    font-size: 11px;
    max-width: 210px;
    padding: 5px;
    border-radius: 4px;
    margin-top: 15px;
    text-align: center;
  }
}

iframe#webWidget {
  right: 0 !important;
}

/*
Zendesk uses `top: -9999; opacity: 0` to hide inactive widget
so we should override positioning only for active state
to prevent invisible widget overlaying main page components
*/
iframe#webWidget.zEWidget-webWidget--active {
  bottom: auto !important;
  top: 64px !important;
  height: calc(100% - 64px) !important;
}

iframe.zEWidget-launcher {
  -webkit-transform: translateZ(0) scale(0.8) !important;
          transform: translateZ(0) scale(0.8) !important;
}

.layout-dashboard .notifications-tr {
  top: 56px !important;
}

.notification {
  background-size: 20px 20px;
  background-position: 20px center;
  background-repeat: no-repeat;
}

.notification.notification-success {
  background-image: url(/images/notification-success.png);
}

.notification.notification-warning {
  background-image: url(/images/notification-warning.png);
}

.notification.notification-info {
  background-image: url(/images/notification-info.png);
}

.notification.notification-error {
  background-image: url(/images/notification-sad.png);
}

.notification-dismiss {
  background: transparent !important;
  color: #ffffff !important;
  font-size: 23px !important;
  top: 8px !important;
  right: 8px !important;
}

.layout-sign .page {
  top: 0;
  width: 100%;
}

.layout-sign .container {
  height: 100%;
}

.layout-sign .content {
  /* height: 100%; */
  padding: 0;
}

.layout-sign .content.content-create-profile {
  height: auto;
}

.layout-sign .content {
  min-height: 100%;
  width: 480px;
  position: absolute;
  right: 0;
  top: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  /*padding-bottom: 50px;*/
}

.layout-sign h1 {
  /*---- global font setting ----*/
  font-weight: 400;
  font-size: 1.3em;
  display: block;
  text-align: center;
}

.layout-sign .btn {
  display: block !important;
  max-width: 210px !important;
  min-width: 0 !important;
  margin: 20px auto !important;
}

.layout-sign .verification-2fa-code-container .verification-code-button {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.layout-sign .verification-2fa-code-container .verification-2fa-code-link-container {
  display: -webkit-flex;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  -webkit-align-items: center;
          align-items: center;
  height: 24px;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  margin: 6px 0 12px;
}

.verification-2fa-code-link {
  font-size: 11px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-margin-before: 0px;
          margin-block-start: 0px;
  -webkit-margin-after: 0px;
          margin-block-end: 0px;
}

.verification-2fa-code-link-disabled {
  color: gray;
  cursor: auto;
  cursor: initial;
  text-decoration: none;
}

.badge-verified {
  margin-right: 12px;
}

.layout-sign .verification-2fa-code-container .verification-code-button .btn {
  margin: 0 !important;
}

.layout-sign .btn-verification .btn {
  max-width: 260px !important;
}

.layout-sign .btn-verification-sm {
  display: none;
}

.layout-sign .left-pane {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: calc(100% - 480px);
  color: #ffffff;
  background: url(/images/Login-Background.jpg) no-repeat center;
  background-size: cover;
  z-index: 1;
  transition: all 0.3s;
}

.sign-card-wrapper {
  width: 100%;
}

.sign-card-wrapper .card {
  max-width: 400px;
}

.layout-sign-switch {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 0 30px 0;
  font-size: 0.86em;
  text-align: center;
}

.layout-sign-switch-create-profile {
  position: relative;
}

.layout-sign .footer {
  display: none;
}

.sign-card-wrapper > div:first-child {
  margin: 0 auto;
  width: 100%;
}

.sign-logo {
  background: url(/images/Login-SL8-Logo.png) no-repeat center;
  background-size: 180px 26px;
  width: 180px;
  height: 26px;
  position: absolute;
  top: 2.15em;
  left: 2.15em;
  cursor: pointer;
}

.sign-content {
  height: calc(100% - 150px);
  margin-top: 6.5em;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.sign-items {
  margin: 0 10em 0 4em;
}

.sign-item {
  display: -webkit-flex;
  display: flex;
  margin: 2.85em 0;
}

.sign-item-icon {
  font-size: 24px;
  width: 50px;
  margin-right: 25px;
  text-align: right;
}

.sign-item-desc {
  width: calc(100% - 75px);
}

.sign-item-title {
  font-size: 1.7em;
}

.sign-item-text {
  font-size: 1em;
  /*---- global font setting ----*/
  font-weight: 400;
  line-height: 1.5em;
}

.sign-item-text a {
  color: #ffffff;
  /*---- global font setting ----*/
  font-weight: 400;
}

.sign-item-dashboard {
  margin-top: 2.85em;
  margin-bottom: 70px;
}

.sign-item-dashboard .sign-item-icon {
  font-size: 44px;
  position: relative;
  top: -2px;
}

.sign-item-dashboard .sign-item-title {
  font-size: 44px;
  position: relative;
  left: -4px;
}

.animation {
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-animation: 0.5s fade-in;
          animation: 0.5s fade-in;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .left-pane {
    background: url(/images/Login-Background@2x.jpg) no-repeat center;
    background-size: cover;
  }

  .sign-logo {
    background: url(/images/Login-SL8-Logo@2x.png) no-repeat center;
    background-size: 180px 26px;
  }
}

@media screen and (max-width: 1199px) {
  .sign-item-dashboard {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 1099px) {
  .sign-items {
    margin: 0 4em 0 2em;
  }

  .sign-item {
    margin: 2.5em 0;
  }

  .sign-item-icon {
    font-size: 20px;
  }

  .sign-item-title {
    font-size: 1.5em;
  }

  .sign-item-dashboard {
    margin-top: 30px;
    margin-bottom: 40px;
  }

  .sign-item-dashboard .sign-item-icon,
  .sign-item-dashboard .sign-item-title {
    font-size: 36px;
  }
}

@media screen and (max-width: 1023px) {
  .sign-card-wrapper .card {
    width: 90% !important;
  }
  .layout-sign .left-pane,
  .layout-sign .content {
    width: 50%;
  }

  .sign-content {
    height: calc(100% - 115px);
  }

  .sign-logo {
    width: 160px;
    background-size: 160px auto;
    top: 1em;
    left: 1em;
  }

  .sign-content {
    margin-top: 4em;
  }

  .sign-items {
    margin: 0 3em 0 1em;
  }

  .sign-item-icon {
    width: 40px;
  }

  .sign-item-dashboard .sign-item-icon,
  .sign-item-dashboard .sign-item-title {
    font-size: 30px;
  }
}

@media screen and (max-width: 767px) {
  .layout-sign .content {
    width: 100%;
    padding-top: 60px;
    position: relative;
    min-height: calc(100% - 70px);
    height: auto;
  }

  /* .layout-sign .content {
    height: 100%;
  } */

  .layout-sign .footer {
    display: block;
    padding-bottom: 20px;
  }

  .layout-sign .sign-content {
    display: none;
  }

  .layout-sign .left-pane {
    display: block;
    width: 100%;
  }

  .layout-sign .left-pane {
    padding: 15px 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: auto;
    z-index: 2;
  }

  .sign-logo {
    position: relative;
    top: auto;
    left: auto;
    margin: 0 auto;
  }

  .layout-sign {
    min-height: 100%;
    height: 100%;
    display: block;
  }

  .layout-sign-switch {
    position: relative;
    text-align: center;
    padding-top: 20px;
  }
}

@media screen and (max-width: 479px) {
  .layout-sign .btn-verification {
    display: none;
  }

  .layout-sign .btn-verification-sm {
    display: block;
  }
}

.layout-slate .page {
  top: 0;
  width: 100%;
}

.layout-slate .container {
  height: 100%;
  padding: 0;
}

.layout-slate .content {
  height: 100%;
}

.layout-slate .footer {
  display: none;
}


