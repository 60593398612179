.field {
  width: calc(100% - 10px);
  margin-left: 5px;
  margin-bottom: 5px;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 1px #cccccc;
  transition: all 0.2s;
  text-align: left;
}

.field:hover {
  cursor: pointer;
  background-color: #f7f6f6;
}

.field-disable.field:hover {
  cursor: initial;
  background-color: #ffffff;
}

.field-title {
  display: flex;
  align-items: center;
  height: 18px;
}

.field-title-history {
  display: flex;
  align-items: center;
}

.field-name {
  color: #8e8e93;
  margin-right: auto;
  padding-right: 5px;
}

.field-data-text {
  width: 60%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
}

.field-data-boolean {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #e6160d;
}

.field-data-boolean-true {
  background-color: #72bb53;
}

/* .field-data-boolean-checkbox {
  padding-top: 2px;
  margin-right: -2px;
} */

.field-info {
  text-align: right;
  margin-top: 3px;
}

.field-info-left {
  text-align: left;
}

/* .field-info-margin {
  margin-top: 8px;
} */

.field-info-coords {
  display: flex;
  justify-content: space-between;
}

.field-info-coords-margin {
  margin-bottom: 4px;
}

.field-info-address {
  margin-left: 5px;
}

.field-key,
.field-value {
  font-size: 10px;
  color: #414042;
  margin-left: 5px;
}

.field-key {
  color: #8e8e93;
}

.field-record-displayName,
.field-record-timestamp,
.field-record-key {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 1px;
}

.field-record-name {
  flex-shrink: 0;
}

.field-record-timestamp {
  flex-shrink: 10000;
}

.field-record-displayName {
  flex-shrink: 100000000;
}

.field-icon {
  font-size: 18px;
  color: #f15a29;
}

.field-data-more {
  color: #f15a29;
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none;
}

.field-image {
  position: relative;
  width: calc(100% + 30px);
  margin: 5px -15px;
  height: 180px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.field-journal-title {
  margin-bottom: 5px;
}

.field-journal-icon {
  margin-left: 12px;
}

.field-journal-long {
  padding: 10px 15px;
}

.field-journal-entry {
  display: flex;
  justify-content: space-between;
  color: #8e8e93;
  margin-bottom: 10px;
  padding: 15px;
  background-color: white;
  box-shadow: 0 1px 2px 1px #cccccc;
}

.field-journal-entry:last-child {
  margin-bottom: 5px;
}

.field-qr {
  display: flex;
}

.field-qr-code {
  width: 100px;
  height: 100px;
}

.field-qr-code-dialog {
  padding: 20px;
}

.field-image-preloader {
  position: initial;
}

.field-location-buttons {
  padding: 10px 0 0 10px;
}

.field-location-buttons-divider {
  display: inline-block;
  width: 1px;
  height: 34px;
  background-color: #eee;
  vertical-align: top;
}

.field-description {
  padding: 10px 10px 10px 15px;
  margin-bottom: 5px;
  font-size: 12px;
}

.hidden {
  display: none;
}

@media screen and (max-width: 400px) {
  .field-qr-code-dialog {
    padding: 10px;
  }
}
