/* for safari */
.gmnoprint map area {
  display: block;
  cursor: pointer !important;
}

/* react-google-maps infoBox: can't redefine inline styles */
.map-info-top {
  transform: translate3d(-50%, -138px, 0) !important;
}

.map-info-bottom {
  transform: translate3d(-50%, 25px, 0) !important;
}

.map-info-window {
  height: 70px;
  padding: 15px;
  display: flex;
  align-items: center;
  background-color: #fff;
  white-space: nowrap;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.map-info-window-image {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-right: 15px;
  background-size: cover;
  border-radius: 5px;
  font-size: 20px;
  text-align: center;
}

.map-info-window-title {
  font-size: 16px;
 /*---- global font setting ----*/
  font-weight: 400;
  white-space: nowrap;
}

.map-info-window-arrow-top,
.map-info-window-arrow-bottom {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
}

.map-info-window-arrow-top {
  bottom: -25px;
  border-top: 25px solid #ccc;
}

.map-info-window-arrow-bottom {
  bottom: 69px;
  border-bottom: 25px solid #ccc;
}

.map-info-window-arrow-top:after,
.map-info-window-arrow-bottom:after {
  content: "";
  display: block;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
}

.map-info-window-arrow-top:after {
  bottom: 2px;
  border-top: 25px solid #fff;
}

.map-info-window-arrow-bottom:after {
  bottom: -27px;
  border-bottom: 25px solid #fff;
}
