.account-dialog {
  min-height: 290px;
}

.account-dialog-left {
  width: 230px;
  position: absolute;
  left: 0;
  top: 60px;
}

.account-dialog-right {
  min-height: 260px;
  width: calc(100% - 230px);
  margin-left: 230px;
}

.account-add {
  padding: 35px 25px;
}

.account-add-title {
  font-size: 22px;
  margin-bottom: 15px;
}

.account-add-desc {
  font-size: 12px;
}

.account-add form {
  max-width: 170px;
  margin: 20px auto 0px auto;
}

.account-add form .btn {
  margin-top: 10px;
}

.account-list-container {
  padding: 0px 15px;
}

.account-list-search {
  background: url('/public/images/search_default.png') no-repeat;
  background-size: 16px;
  background-position: 15px 15px;
}

.account-list-search input {
  width: calc(100% - 27px) !important;
}

.account-item {
  display: flex;
  align-items: center;
  padding: 10px 0px;
}

.account-item-image {
  display: inline-block;
  width: 44px;
  height: 44px;
  border-radius: 5px;
  margin-right: 10px;
  background-size: 44px;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  line-height: 42px;
  font-size: 20px;
}

.account-add .form-error {
  font-size: 12px !important;
  margin-top: 10px;
}

@media screen and (min-width: 992px) {
  .account-list {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -webkit-box-sizing: border-box;
  }
}

@media screen and (min-width: 601px) {
  .account-list-container {
    max-height: 360px;
  }

  .account-list {
    overflow-y: auto;
    -webkit-box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
  }
}

@media screen and (max-width: 600px) {
  .account-list-container {
    max-height: 260px;
    display: inline-block;
    width: 100%;
    overflow-y: auto;
  }

  .account-dialog {
    /*overflow-y: auto;*/
    -webkit-box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
  }

  .account-dialog-left {
    position: relative;
    top: auto;
    width: 100%;
  }

  .account-add {
    padding: 20px;
    border-bottom: 1px solid #eeeeee;
  }

  .account-add form {
    margin-top: 0;
  }

  .account-add-title {
    margin-bottom: 5px;
  }

  .account-dialog-right {
    margin-left: 0;
    width: 100%;
    border: 0 !important;
  }
}
