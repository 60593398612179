.slate-view {
  outline: none;
}

.slate-view .inner-page-title {
  padding: 20px 0;
}

.slate-view-container {
  /*72px - inner-page-header height*/
  height: calc(100% - 80px);
}

.slate-view-type {
  margin-left: 10px;
}

.slate-view-counter {
  padding: 0 9px;
  height: 23px;
  line-height: 23px;
  font-size: 13px;
  border-radius: 12px;
  margin-left: 10px;
/*---- global font setting ----*/
  font-weight: 400;
  flex-shrink: 0;
}

.slate-view-actions {
  display: flex;
  align-items: center;
}

.slate-view-actions-refresh {
  margin-right: 5px;
}

.slate-view-actions-copy-table {
  margin-left: 5px;
}

.slate-view-actions-divider {
  height: 34px;
  width: 1px;
  border-right: 1px solid #eee;
}

.slate-view-dropdown {
  padding: 0 5px;
  height: 100%;
  line-height: 30px;
  margin-left: -1px;
  cursor: pointer;
}

.slate-view-popover-item {
  padding: 10px 15px;
}

.slate-view-dropdown:hover {
  border-bottom-right-radius: 4px;
}

.slate-view-dropdown:hover,
.slate-view-dropdown-active {
  background: #ffffff;
  border-top-right-radius: 4px;
  color: #414042 !important;
  box-shadow: rgba(65, 64, 66, 0.12) 0 1px 6px,
    rgba(65, 64, 66, 0.12) 0 1px 4px;
  cursor: pointer;
}

.slate-view-popover {
  border-top-right-radius: 0 !important;
}

.slate-view-share-dialog {
  padding: 20px 24px;
  text-align: center;
}

.slate-view-share-dialog-url {
  padding: 20px 90px;
  text-align: center;
  font-weight: bolder;
  font-size: 18px;
  word-wrap: break-word;
}

.slate-view-share-dialog-url input{
  text-align: center;
  font-weight: bolder;
  font-size: 18px;
}

.slate-view-settings-dialog {
  padding: 20px 24px;
  text-align: left;
}

.slate-view-settings-dialog-btn {
  margin-right: 10px;
}

.slate-list-item {
  display: block;
  padding: 10px 25px;
  text-align: left;
}

.slate-view-create-button {
  position: absolute;
  top: 12px;
  right: 58px;
}

.slate-view-selector-container {
  display: flex;
}

.slate-view-list {
  width: 100%;
}

.slate-view-list-title {
  font-size: 20px;
  font-weight: 500;
  padding: 15px 25px;
  border-bottom: 1px solid #eeeeee;
  text-align: left;
}

.slate-view-list-title .fa {
  margin-right: 10px;
}

.slate-view-list-container {
  padding: 0 10px;
  max-height: 320px;
  overflow-y: auto;
  border-right: 1px solid #eeeeee;
}

.slate-view-list-item {
  padding: 10px 0;
  border-bottom: 1px solid #eeeeee;
}

.slate-view-list-item-button {
  position: relative;
  display: flex;
  padding: 0 52px 0 15px;
  align-items: center;
}

.slate-view-list-item-name {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.slate-view-list-count {
  height: 23px;
  line-height: 23px;
  position: absolute;
  top: 50%;
  right: 10px;
  border-radius: 12px;
  font-size: 13px;
/*---- global font setting ----*/
  font-weight: 400;
  color: white;
  padding: 0 9px;
  transform: translateY(-50%);
}

.slate-view-empty {
  font-size: 16px;
  margin-top: 20px;
}

.slate-view-empty-create-button {
  margin-top: 20px;
}

.slate-view-inner {
  height: 100%;
  position: relative;
}

.slate-view-inner-expanded {
  position: absolute !important;
  top: 2px;
  bottom: 2px;
  left: 2px;
  right: 2px;
  height: calc(100% - 4px) !important;
  transition: all 0.3s;
  z-index: 5;
}

.slate-view-data {
  height: 100%;
  background: #f7f6f6;
  -webkit-touch-callout: none;
  position: relative;
}

.slate-view-data-expanded {
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100% !important;
}

.slate-view-data-expanded:before {
  display: block;
  content: '';
  position: fixed;
  top: 60px;
  right: 0;
  bottom: 0;
  left: 0;
  background: #f7f6f6;
}

@media screen and (min-width: 991px) {
  .slate-view-data-with-pane {
    width: calc(100% - 62px);
  }

  .slate-view-data-docked {
    width: calc(100% - 410px);
  }

  .slate-view-data-expanded.slate-view-data-docked {
    width: calc(100% - 405px);
  }
}

@media screen and (max-width: 990px) {
  .slate-view-inner {
    overflow: hidden;
  }
}

@media screen and (max-width: 500px) {
  .slate-view-selector-container {
    flex-wrap: wrap;
  }
}
