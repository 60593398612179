.expander {
  position: absolute;
  right: 0;
  top: 0;
  width: 26px;
  height: 26px;
  z-index: 1001;
  text-align: center;
  line-height: 26px;
  border-radius: 4px;
  margin: 5px;
  cursor: pointer;
}
