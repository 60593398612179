
.layout-sign .sign-card-wrapper .legal-info {
  display: none;
}

.layout-sign .left-pane .legal-info {
  display: block;
  position: absolute;
  left: 2.15em;
  bottom: 0.7em;
}

.layout-sign .left-pane .legal-info {
  color: #c7c7c7;
}

@media screen and (max-width: 767px) {
  .layout-sign .left-pane .legal-info {
    display: none;
  }

  .layout-sign .sign-card-wrapper .legal-info {
    display: block;
    border: 0;
    margin-top: 0;
  }
}

.legal-info {
  font-size: 10px;
  transition: all 0.4s;
}

.legal-info a {
  color: #c7c7c7;
}

.legal-info > div {
  margin: 5px 0;
}

.legal-info > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.legal-info > div > span {
  display: inline-block;
  border-right: 1px solid #c7c7c7;
  padding: 0 5px;
/*---- global font setting ----*/
  font-weight: 400;
}

.legal-info > div > span:last-child {
  border-right: 0;
}

.legal-info .footer-env {
  font-weight: 600;
}
