.table-filter {
  max-width: 300px;
  width: 90%;
  margin: 20px auto;
}

.table-filter-input {
  display: flex;
  align-items: center;
}

.table-filter-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
