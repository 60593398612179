body {
  color: #414042;
  background-color: #f7f6f6;
}

body.dialog-open,
body.slate-pane-open {
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #eee inset;
}

a, .link {
  color: #f15a29;
  text-decoration: none;
  cursor: pointer;
}

a:hover, .link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.btn {
  box-shadow: none!important;
}

.btn span {
  text-transform: inherit!important;
}

.dialog .btn-action {
  margin: 5px;
}

.text-center {
  text-align: center;
}

.text-small {
  font-size: 11px;
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-left: -20px;
}

@media screen and (max-width: 767px) {
  .layout-sign-right .footer {
    border-color: #d8d8d8;
    color: #ababab;
  }


  .footer,
  .footer a {
    color: #ababab;
  }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  select,
  textarea,
  input {
    font-size: 16px!important;
  }
}


