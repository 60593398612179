body,
#root {
  /*position: relative;*/
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

body,
#root {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
/*---- global font setting ----*/
  font-weight: 400 !important;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  overflow-y: hidden;
}

.body {
  z-index: 1;
}

#root {
  z-index: 2;
  -webkit-overflow-scrolling: touch;
}

.app {
  width: 100%;
  height: 100%;
}

h1 {
  font-weight: 600;
  font-size: 1.8em;
}

.grid-header {
  overflow-x: hidden !important;
}

.app-env-topbar {
  position: absolute;
  top: 0;
  width: 100%;
  height: 6px;
  z-index: 2000;
}

.views,
.view {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 500;
}

.views {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.view {
  overflow: hidden;
  box-sizing: border-box;
}

.pages {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.page {
  box-sizing: border-box;
  position: absolute;
  right: 0;
  top: 60px;
  width: calc(100% - 250px);
  height: 100%;
  /*transition: width .25s;*/
}

.sidebar-collapsed .page {
  width: calc(100% - 60px);
  /*transition: width .25s;*/
}

.container {
  padding: 0 30px;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  height: calc(100% - 61px);
  position: relative;
  -webkit-overflow-scrolling: touch;
}
.container.no-padding-container {
  padding: 0;
}
.container.no-padding-container .inner-padding {
  padding: 0 30px;
}
.container.no-scroll-container {
  height: calc(100% - 32px);
  overflow-y: hidden;
}

.layout-dashboard .content {
  /* Footer desktop height - 61px*/
  min-height: calc(100% - 61px);
}

.layout-dashboard .content.content-fixed {
  height: calc(100% - 2px);
  min-height: 0;
}

.layout-dashboard .content > div {
  height: 100%;
}

/* .loading-logo {
  width: 51px;
  height: 44px;
  background-size: 51px 44px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -25px;
} */

.field-to-verify {
  position: relative;
}

.field-to-verify .btn-verify,
.field-to-verify .badge {
  width: 130px;
  text-align: center;
  position: absolute;
  right: -150px;
  top: -10px;
}

.field-to-verify .btn-verify-2FA,
.field-to-verify .badge-verified-second{
  width: 130px;
  text-align: center;
  position: absolute;
  right: -300px;
  /*margin-left: 24px;*/
  top: -10px;
}

.field-to-verify .btn-disable-2FA {
  width: 130px;
  text-align: center;
  position: absolute;
  right: -450px;
  /*margin-left: 24px;*/
  top: -10px;
}

.field-to-verify .badge {
  height: 34px;
  margin-top: 20px;
}

.form-error-action {
  display: none;
}

.pages.slate-pane-open .container {
  overflow: inherit;
}

.inner-page {
  /*31px - breadcrumbs height*/
  min-height: calc(100% - 31px);
  /* -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden; */
}

.content-fixed .inner-page {
  height: calc(100% - 31px);
}

.inner-page-header {
  display: flex;
}

.inner-page-title {
  display: flex;
  align-items: center;
  width: 100%;
}

.inner-page-title h1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
}

@media screen and (min-width: 992px) {
  .sidebar-collapsed .app-content {
    padding-left: 80px;
    transition: all 0.35s;
  }

  .pages.slate-pane-open {
    z-index: 500;
  }
}

@media screen and (max-width: 991px) {
  .sidebar-opened .page {
    overflow: hidden;
  }

  .sidebar-overlay {
    content: '';
    display: block;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .page,
  .sidebar-collapsed .page {
    width: 100%;
  }

  .sidebar-opened .page,
  .sidebar-opened .topbar {
    overflow: hidden;
    position: fixed;
    transform: translateX(250px);
    transition: all 0.35s;
  }
}

.scrollbar-measure {
  width: 100px;
  height: 100px;
  overflow: scroll;
  position: absolute;
  top: -9999px;
}


@media screen and (max-width: 1280px) {
  .field-to-verify .btn-verify,
  .field-to-verify .badge {
    position: relative;
    right: 0;
    top: 0;
    margin-top: 0;
    margin-right: 12px;
  }

  .field-to-verify .btn-verify-2FA,
  .field-to-verify .badge-verified-second {
    position: relative;
    right: 0;
    top: 0;
    margin-top: 0;
  }

  .field-to-verify .btn-disable-2FA {
    position: relative;
    right: 0;
    top: 0;
    margin-top: 0;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 1.4em;
  }

  .container {
    padding: 0 10px;
  }

  .layout-dashboard .content {
    min-height: calc(100% - 79px);
  }

  .form-error-action {
    display: block;
    background: #f15a29;
    color: #ffffff;
    font-size: 11px;
    max-width: 210px;
    padding: 5px;
    border-radius: 4px;
    margin-top: 15px;
    text-align: center;
  }
}

iframe#webWidget {
  right: 0 !important;
}

/*
Zendesk uses `top: -9999; opacity: 0` to hide inactive widget
so we should override positioning only for active state
to prevent invisible widget overlaying main page components
*/
iframe#webWidget.zEWidget-webWidget--active {
  bottom: auto !important;
  top: 64px !important;
  height: calc(100% - 64px) !important;
}

iframe.zEWidget-launcher {
  transform: translateZ(0) scale(0.8) !important;
}
