/* react-cropper */
.cropper-circle .cropper-view-box {
  border-radius: 50%;
}

.cropper-point {
  border-radius: 50%;
}

/* react-file-drop */
.file-drop
  > .file-drop-target.file-drop-dragging-over-target
  .avatar-uploader-dialog {
  background-color: rgba(4, 134, 220, 0.05);
  color: #0486dc;
}

.avatar-uploader-dialog {
  margin: 0;
  padding: 50px 0px;
  background-color: #f1f1f1;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;
}

.avatar-uploader-dialog:hover {
  background-color: rgba(4, 134, 220, 0.05);
  color: #0486dc;
}
