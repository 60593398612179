.color-picker {
  display: inline-block;
  margin: 0 10px;
}

.color-picker-swatch {
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
  display: inline-block;
  cursor: pointer;
}

.color-picker-color {
  width: 64px;
  height: 24px;
  border-radius: 2px;
}

.github-picker > span > div {
  /* Can't control inner GithubSwatch's styles props yet */
  width: 43px !important;
  height: 20px !important;
}
