.page_account-apikey .card {
  margin-top: 0px !important;
}

.page_account-apikey .btn {
  margin-top: 20px;
}

.apikey-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background-color: #f7f6f6;
  padding: 10px 0 10px 15px;
  margin-bottom: 10px;
}

.apikey-item:last-of-type {
  margin-bottom: 0;
}

.apikey-item-name {
  font-size: 16px;
}

.apikey-item-options {
  font-size: 12px;
}

.apikey-item-actions {
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.apikey-confirm {
  text-align: center;
  padding: 20px 0 10px 0;
}

.apikey-confirm-name,
.apikey-confirm-key {
  font-size: 18px;
}

.apikey-confirm-key {
  margin: 10px 0;
  min-height: 50px;
  padding: 14px 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
  background-color: #03bf98;
  color: #fff;
  word-wrap: break-word;
}

.apikey-confirm-descr {
  margin-bottom: 30px;
}
