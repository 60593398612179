.footer {
  width: 100%;
  height: 60px;
  position: relative;
  box-shadow: -2px 0px 5px 0px #cccccc;
}

.footer:empty {
  display: none;
}

@media screen and (min-width: 992px) {
  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 12px 43px 12px 280px;
    /*background-color: #f7f6f6;*/
    /*border-top: 1px solid rgb(211, 211, 211);*/
    background-color: rgb(238, 238, 238);
    border-top: 1px solid rgb(230, 230, 230);
  }

  .container:not(.with-empty-footer) {
    height: calc(100% - 120px); /* header + footer height */
  }

  .sidebar-collapsed .footer {
    padding-left: 90px;
  }
}
