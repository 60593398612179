.layout-slate .page {
  top: 0;
  width: 100%;
}

.layout-slate .container {
  height: 100%;
  padding: 0;
}

.layout-slate .content {
  height: 100%;
}

.layout-slate .footer {
  display: none;
}
