.breadcrumbs {
  padding: 15px 0px 0px 0px;
}
.breadcrumbs-item,
.breadcrumbs-item a,
.breadcrumbs-item .breadcrumbs-chevron {
  display: inline-block;
}

.breadcrumbs-item .breadcrumbs-chevron {
  margin: 0px 10px;
}
