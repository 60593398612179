.slate-generator-title {
  margin-bottom: 0;
}

.slate-generator-container {
  padding-left: 30px;
}

.slate-generator-item {
  display: flex;
  flex-wrap: wrap;
}

.slate-generator-item-name {
  font-weight: 500;
  margin-right: 15px;
  width: 100px;
  text-align: right;
  padding: 3px 0;
}

.slate-generator-item-value {
  padding: 3px 0;
  word-break: break-all;
}

.slate-generator-warning-wrapper {
  margin-bottom: 20px;
}

@media screen and (max-width: 800px) {
  .slate-generator-container {
    padding: 0;
  }

  .slate-generator-item-name {
    width: auto;
  }
}

.slate-generator-qr-dev {
  width: 200px;
  height: 200px;
  max-height: 200px;
  position: relative;
  margin-top: 7px;
  margin-bottom: 30px;
}

.slate-generator-qr {
  width: 200px;
  height: 200px;
  max-height: 200px;
  position: relative;
  margin-top: 7px;
  margin-bottom: 30px;
}
.slate-generator-qr:before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  border: 5px solid #f15a29;
  border-color: #f15a29 #f15a29 transparent #f15a29;
  border-radius: 50%;
  animation: loader 1s linear infinite;
}
.slate-generator-qr > img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
@keyframes loader {
  0% {
    transform: translate(-50%,-50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%,-50%) rotate(360deg);
  }
}