.map-view {
  height: 100%;
}

.map-view-header {
  position: relative;
  height: 36px;
  z-index: 30;
}

.map-view-header-checkbox {
  width: 250px;
  height: 36px;
  display: flex;
  align-items: center;
  margin-left: 7px;
}
