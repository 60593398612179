/* Prevent selecting */
.slate-view-settings {
  user-select: none;
}

.settings-header {
/*---- global font setting ----*/
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 0;
}

.settings-rules-add {
  margin-top: 20px;
}

.settings-rules-operator {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-right: 20px;
}

.settings-rules-operator-text {
  font-size: 16px;
/*---- global font setting ----*/
  font-weight: 400;
}

.settings-rule {
  position: relative;
  display: flex;
  min-height: 60px;
  padding-left: 10px;
  background-color: #eee;
  margin: 20px 0;
}

.settings-rule-items-container {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.settings-rule-field {
  position: relative;
  margin-right: 30px;
}

.settings-rule-operator {
  margin: 0 30px 0 0;
}

.settings-rule-pattern-text {
  margin: -8px 30px 0 0;
}

.settings-rule-pattern-selector {
  margin: 0 30px 0 0;
}

/* TODO there is dublicate of max width from styles.js. Need to fix it */
.settings-rule-field,
.settings-rule-operator,
.settings-rule-pattern-text,
.settings-rule-pattern-selector {
  max-width: 220px;
  flex-grow: 1;
}

.settings-rule-btn-remove {
  position: absolute;
  top: 6px;
  right: -3px;
}

.settings-record-btn-remove {
  position: absolute;
  top: 0;
  right: -50px;
}

.settings-columns-item-btn-remove {
  display: flex;
  align-items: center;
}

.settings-rule-spinner {
  padding-top: 15px;
}

.settings-fields-content {
  display: flex;
  flex-wrap: wrap;
}

.settings-fields-available {
  margin-right: 50px;
}

.settings-fields-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.settings-fields-item:last-child {
  margin-bottom: 0;
}

.settings-options {
  margin-left: 40px;
  margin-bottom: 10px;
}

.settings-text {
  font-size: 16px;
}

.settings-radio {
  margin: 10px 0 5px 0;
}

.settings-record-selector {
  position: relative;
  max-width: 360px;
}

@media screen and (max-width: 1160px) {
  .settings-rule,
  .settings-rule-items-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .settings-rule {
    padding-right: 10px;
  }

  .settings-rule-field,
  .settings-rule-operator,
  .settings-rule-pattern-text,
  .settings-rule-pattern-selector {
    margin-right: 0;
    width: 100%;
  }

  .settings-rule-btn-remove {
    top: 0;
  }

  .settings-rule-date-type-fixed label {
    display: block !important;
  }
}

.date-picker {
  display: inline-block;
  vertical-align: top;
}

.pin-colors {
  border: 1px solid rgb(211, 211, 211);
  padding: 16px;
  margin: 16px 0;
  position: relative;
  min-height: 60px;
  padding-left: 10px;
  background-color: #fafafa;
}

@media screen and (max-width: 420px) {
  .settings-rule {
    padding-right: 40px;
  }
}

.blur {
  filter: blur(.2rem)
}

.settings-rule-content-container {
  position: relative;
}