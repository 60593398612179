.settings-fields {
  display: flex;
}

.settings-fields .separator {
  width: 50px;
  align-self: center;
}

.settings-fields .separator > div {
  border: 1px solid #cccccc;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 28px;
  margin: 10px auto;
  cursor: pointer;
}

.settings-fields-column {
  width: calc((100% - 50px) / 2);
  max-width: 640px;
  height: 70vh;
}

.settings-fields-column-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.settings-fields-column-title {
/*---- global font setting ----*/
  font-weight: normal;
}

.settings-fields-column-container {
  width: 100%;
  height: calc(100% - 96px);
  border: 1px solid #cccccc;
  border-top: none;
  overflow-y: auto;
}

.settings-fields-column-container ul {
  width: 100%;
}

.settings-fields-buttons {
  display: flex;
}

.settings-fields-button {
  width: 30px;
  height: 30px;
  border: 1px solid #cccccc;
  margin-left: 10px;
  text-align: center;
  line-height: 28px;
  cursor: pointer;
}

.settings-field-available {
  border-bottom: 1px solid #cccccc;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  display: flex;
  cursor: pointer;
  transition: background-color 0.2s;
}

.settings-field-available:hover {
  background-color: #c3e2f6;
}

.settings-field-available-highlighted {
  background: #a6d4f2 !important;
  border-bottom: 1px solid #69b6ea !important;
}

.settings-fields-nothing {
  padding: 10px;
}

.settings-field-header {
  padding-left: 10px;
  background-color: #f7f6f6;
  cursor: default;
}

.settings-field-header:hover {
  background-color: #f7f6f6;
}

.settings-field-selected {
  border-bottom: 1px solid #cccccc;
  display: flex;
  justify-content: space-between;
  align-content: center;
  /* padding: 10px 10px 10px 15px; */
}

.settings-field-selected-highlighted {
  background: #a6d4f2;
  border-bottom: 1px solid #69b6ea;
}

.settings-field-body {
  display: flex;
  justify-content: space-between;
}

.settings-field-title {
  width: 100%;
  display: block;
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
}

.settings-field-type {
  color: #0486dc;
  font-size: 12px;
}
.part-left,
.part-right {
  display: inline-block;
  width: 50%;
}
/* .available-fields-select-wrapper {
  margin-top: 13px;
  vertical-align: top;
  margin-bottom: 12px;
}
.available-fields-select-wrapper span {
  line-height: 30px;
  padding-right: 10px;
  color: rgb(65, 64, 66);
  font-weight: 500;
}
.available-fields-select-wrapper .flex {
  justify-content: flex-end;
  display: flex;
} */

@media screen and (max-width: 470px) {
  .part-left,
  .part-right {
    width: 100%;
  }
  .part-right {
    margin-bottom: 30px;
  }
  /* .available-fields-select-wrapper .flex {
    justify-content: flex-start;
  } */
}

@media screen and (max-width: 700px) {
  .settings-fields {
    flex-direction: column;
  }

  .settings-fields-column {
    width: 100%;
  }

  .settings-fields .separator {
    width: 80px;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
}

/* @media screen and (min-width: 991px) and (max-width: 1100px) {
  .available-fields-select-wrapper span {
    display: none;
  }
} */

/* @media screen and (min-width: 700px) and (max-width: 885px) {
  .available-fields-select-wrapper span {
    display: none;
  }
} */

.select-wrapper {
  height: 30px;
  padding-bottom: 10px;
}
