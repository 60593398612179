@media screen and (min-width: 992px) {
  .icon-medium-screen-correction {
    padding-left: 20px;
  }
}

.icon-all-screens-correction {
  padding-left: 20px;
}

.search-error {
  color: rgb(241, 90, 41);
  font-size: 11px;
  position: absolute;
  bottom: -3px;
  left: 20px;
}

.search-error.hidden {
  display: none;
}

/* Important cause it should override other style with important */
.search-input-wrapper input {
  font-size: 17px !important;
}

.reset-form-button {
  position: absolute;
  top: 20px;
  right: -40px;
}

.settings-name-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.settings-basic-info-box {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
}

.settings-type-selector {
  margin-top: 20px;
  width: 100%;
}

.settings-refresh-checkbox {
  width: 280px !important;
}

.slate-view-refresh-checkbox {
  width: 165px !important;
  margin-right: 20px;
  padding: 4px;
  border-radius: 7px;
  border: solid 1px transparent;
  box-sizing: border-box;
}

.settings-refresh-checkbox-animation {
  width: 165px !important;
  margin-right: 20px;
  padding: 4px;
  border-radius: 7px;
  border: solid 1px rgb(241, 90, 41);
  box-sizing: border-box;
  background: white;
  background: linear-gradient(to left, white 50%, rgb(241, 90, 41, .5) 50%) right;
  background-size: 200%;
  animation: load-anim 30s infinite linear;
  -webkit-animation: load-anim 30s infinite linear;
}

@-webkit-keyframes load-anim {
  100%{
    background-position: left;
  }
}

@keyframes load-anim {
  100%{
    background-position: left;
  }
}

.settings-actions {
  position: relative;
  display: flex;
}

.settings-actions-wrapper {
  justify-content: space-between;
  display: flex;
}

.settings-action-cancel-btn {
  margin-right: 20px;
}

@media screen and (max-width: 991px) {
  .reset-form-button {
    right: 0;
  }

  .search-error {
    left: 0;
    bottom: 0;
  }
}

@media screen and (max-width: 660px) {
  .settings-name-wrapper {
    display: flex;
    flex-direction: column;
  }

  .settings-source-selector {
    margin-top: 15px;
  }
}

.settings-source-selector {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.settings-source-title {
  color: #c0c0c0;
  font-size: 16px;
  display: flex;
  align-items: center;
  width: 86%;
  justify-content: space-between;
}

.settings-source-subtitle {
  width: 100%;
  font-size: 10px;
  padding-top: 5px;
}