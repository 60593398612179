.tools-export-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tools-export-tab {
  min-height: 400px;
}

.tools-export-dialog-body {
  padding: 20px 24px;
  text-align: left;
}

.tools-export-bold {
  font-weight: 500;
}

.jobs-list-title {
  display: flex;
  margin-top: 20px;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.jobs-list-empty {
  font-size: 16px;
  padding-top: 10px;
}

.jobs-item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.jobs-list-title-name,
.jobs-list-title-date,
.jobs-item-name,
.jobs-item-date {
  width: 180px;
  padding-right: 10px;
}

.jobs-list-title-status,
.jobs-item-status-name,
.jobs-item-status-progress {
  width: 100px;
}

.jobs-item-status {
  display: flex;
  align-items: center;
  width: 220px;
  padding-right: 10px;
}

.jobs-list-title-name,
.jobs-list-title-date,
.jobs-list-title-status {
  font-weight: 500;
}

.jobs-item-title {
  width: 70px;
  font-weight: 500;
}

.jobs-item-buttons {
  display: flex;
  margin-left: auto;
}

.jobs-item-btn-text {
  margin-right: 10px;
}

.jobs-item-btn-icon {
  margin-right: 5px;
}

.jobs-item-btn-icon:last-child {
  margin-right: 0;
}

.disabled-link {
  pointer-events: none;
  cursor: default;
}

@media screen and (max-width: 1079px) {
  .jobs-item-buttons {
    margin-top: 10px;
  }
}

@media screen and (max-width: 640px) {
  .jobs-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .jobs-item-name,
  .jobs-item-date,
  .jobs-item-status {
    display: flex;
    width: 100%;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 478px) {
  .tools-export-header {
    display: block;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 420px) {
  .jobs-item-status-progress {
    width: 80px;
  }

  .jobs-item-buttons {
    flex-direction: column;
    margin-right: auto;
  }

  .jobs-item-buttons-actions {
    margin-top: 10px;
  }
}
