.topbar {
  position: absolute;
  left: 0;
  top: 0;
  height: 60px;
  border-bottom: 1px solid;
  background-color: #ffffff;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
@media screen and (min-width: 992px) {
  .topbar {
    margin-left: 250px;
    width: calc(100% - 250px);
  }
  .sidebar-collapsed .topbar {
    margin-left: 60px;
    width: calc(100% - 60px);
  }
}
.profile-wrapper {
  display: inline-block;
  max-width: 340px;
  display: inline-block;
  float: right;
}
.topbar-user-profile {
  height: 60px;
  margin-left: 10px;
  padding: 0 10px;
  margin-right: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  background: rgba(255, 255, 255, 0);
  transition: all 0.2s;
}

.topbar-search {
  display: inline-block;
  /* width: calc(100% - 350px); */
  width: 246px;
  margin-right: 25px;
  margin-left: 29px;
  position: relative;
  top: -5px;
}

.sidebar-collapsed .topbar-search {
  /* width: calc(100% - 358px); */
  width: 246px;
}
.inline {
  display: inline-block;
}

@media screen and (max-width: 991px) {
  .topbar-search {
    width: calc(100% - 443px);
  }
  .topbar-search {
    display: none;
  }
  .topbar-search.toggled {
    display: inline-block;
    position: absolute;
    width: calc(100% - 146px);
    background-color: #eeeeee;
    height: 100%;
    margin-right: 0;
    margin-left: 0px;
    z-index: 2;
  }
}

.topbar-user-profile:hover,
.topbar-user-profile.open {
  background: rgba(255, 255, 255, 0.3);
  transition: all 0.2s;
}

.topbar-user-profile .fa {
  font-size: 18px;
  padding-left: 10px;
}

.topbar-user-name {
  font-size: 12px;
  padding-right: 15px;
}
@media screen and (max-width: 475px) {
  .topbar-user-name {
    display: none;
  }
}

.topbar-toggle {
  margin: 9px 6px 3px 6px !important;
}

.topbar-inner {
  height: 100%;
}

.topbar-dialog {
  padding-top: 0px !important;
}

.popover {
  width: 170px;
}

.popover-item {
  padding: 10px;
  color: rgba(0, 0, 0, 0.7);
  text-decoration: none;
  cursor: pointer;
  display: block;
  min-width: 100px;
  position: relative;
}

.popover-item:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 0;
  transition: all 0.2s;
  background: rgba(0, 0, 0, 0.07);
}

.popover-item:hover {
  color: rgba(0, 0, 0, 1);
  text-decoration: none;
}

.popover-item:hover:after {
  right: 0;
  width: 100%;
  transition: all 0.2s;
}

.avatar-default {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  background-size: cover;
}
