.slate-page {
  /* width: 96%; */
  height: 100%;
  /* max-width: 600px; */
  /* margin: 0 auto; */
}

.slate-page .slate-pane-wrapper {
  height: 100%;
}

.slate-page .slate-pane-container {
  position: relative;
  max-width: 600px;
  margin: 0 auto;
}

.slate-page .slate-pane-header-fixed,
.slate-page .slate-pane-header-top-shadow,
.slate-page .slate-pane-cover {
  max-width: 600px;
}

.slate-page .slate-pane-cover {
  position: relative;
}
