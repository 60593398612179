.page_account-settings .card {
  margin-top: 0px !important;
}

.page_account-settings form {
  width: 50%;
}

.page_account-settings .avatar-preview {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.page_account-settings .avatar-preview > div {
  margin-right: 10px;
}

.page_account-settings .avatar-preview .btn {
  margin-top: 0;
}

.page_account-settings .btn {
  margin-top: 20px;
}

.page_account-settings-dialog {
  padding: 20px 24px;
  text-align: left;
}

.page_account-settings-message {
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .page_account-settings form {
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .page_account-settings .avatar-preview .btn {
    margin-top: 20px;
  }
}
