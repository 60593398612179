.image-loader {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.image-loader-inner {
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.5s;
}

.image-loader-inner-loaded {
  opacity: 1;
}
