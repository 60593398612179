.field-collapsible {
  position: relative;
  cursor: pointer;
}

.field-collapsible-disable {
  background-color: #f7f6f6;
  cursor: default;
}

.field-collapsible-container {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  max-width: 600px;
}

.field-collapsible-data {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 0;
}

.field-collapsible-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.field-collapsible-btn {
  margin-right: 10px;
  cursor: pointer;
}

/* .field-collapsible-btn {
  top: 8px;
  left: 6px;
} */

.field-collapsible-type {
  color: #1e92df;
  font-size: 12px;
  margin-left: 10px;
}

@media screen and (max-width: 600px) {
  .field-collapsible-container {
    max-width: 400px;
  }
}
