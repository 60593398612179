.layout-dashboard .notifications-tr {
  top: 56px !important;
}

.notification {
  background-size: 20px 20px;
  background-position: 20px center;
  background-repeat: no-repeat;
}

.notification.notification-success {
  background-image: url(/images/notification-success.png);
}

.notification.notification-warning {
  background-image: url(/images/notification-warning.png);
}

.notification.notification-info {
  background-image: url(/images/notification-info.png);
}

.notification.notification-error {
  background-image: url(/images/notification-sad.png);
}

.notification-dismiss {
  background: transparent !important;
  color: #ffffff !important;
  font-size: 23px !important;
  top: 8px !important;
  right: 8px !important;
}
