.slate-pane {
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 1002;
  box-shadow: -6px 0px 6px rgba(0, 0, 0, 0.15);
  transition: all ease 0.3s;
  background-color: #eeeeee;
}
.slate-full-page-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.slate-full-page {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 2;
}

.slate-full-page-wrap {
  overflow: hidden;
  width: 100%;
  height: calc(100% - 36px);
  background-color: #001b38;
}

.slate-full-page-for-not-pane-wrap {
  height: 100%;
}

.spinner {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url(/images/loader.png) #001b38 no-repeat center center;
  -webkit-animation-name: rotate;
  -webkit-animation-duration:1s;
  -webkit-animation-iteration-count:infinite;
  -webkit-animation-timing-function:linear;
  -moz-animation-name: rotate;
  -moz-animation-duration:1s;
  -moz-animation-iteration-count:infinite;
  -moz-animation-timing-function:linear;
}

@-webkit-keyframes rotate {
  from {-webkit-transform:rotate(0deg);}
  to {  -webkit-transform:rotate(360deg);}
}

@-moz-keyframes rotate {
  from {-moz-transform:rotate(0deg);}
  to {  -moz-transform:rotate(360deg);}
}

.slate-full-page-for-not-pane {
  max-height: 1020px;
  max-width: 620px;
}
.slate-pane.slate-pane-docked,
.slate-pane.slate-pane-active {
  transition: all ease 0.3s;
  transform: translateY(0px);
}

.slate-pane.slate-pane-hidden {
  transition: all ease 0.3s;
  box-shadow: none;
}

.slate-pane-bar {
  height: 36px;
  line-height: 36px;
  text-align: center;
  display: flex;
  position: relative;
  z-index: 30;
  visibility: hidden;
}
.content-fixed .slate-pane-bar {
  visibility: visible;
}

.slate-pane-bar-actions,
.slate-pane-bar-actions-right {
  display: flex;
}

.slate-pane-bar-actions {
  width: 100%;
}

.slate-pane-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  background: rgba(0, 0, 0, 0.2);
  display: none;
  transition: all 0.4s;
}

.slate-pane-wrapper {
  width: 100%;
  height: calc(100% - 36px);
  position: relative;
  overflow: hidden;
}

.slate-pane-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  transform: translate3d(0px, 0px, 0px);
  -webkit-overflow-scrolling: touch;
  top: 0;
}

.slate-pane-container {
  position: absolute;
  width: 100%;
  transform: translate3d(0px, 0px, 0px);
}

.slate-pane-content {
  position: absolute;
  will-change: transform;
  width: 100%;
  top: 300px;
  transform: translate3d(0px, 0px, 0px);
  background: #eeeeee;
}

.slate-pane-header-fixed {
  display: none;
  -webkit-transform: translate3d(0, 0, 0);
}

.slate-pane-header-fixed.visible {
  display: block;
  position: absolute;
  top: -1px;
  width: 100%;
  height: 75px;
  z-index: 40;
  overflow: hidden;
}
.slate-pane-header-fixed.visible:after {
  box-shadow: inset 0 85px 30px -50px rgba(53, 53, 53, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
  display: block;
  background-color: #414042;
}

.slate-pane-header {
  position: absolute;
  will-change: transform;
  width: 100%;
  height: 600px;
  top: -300px;
  transform: translate3d(0px, 0px, 0px);
  overflow: hidden;
  z-index: 16;
}

.slate-pane-cover {
  height: 600px;
  width: 100%;
  overflow: hidden;
}

.slate-pane-header.hidden,
.slate-pane-cover.hidden {
  display: none;
}

.slate-pane-cover-image {
  width: 100%;
  background-size: cover;
  background-position: 50% calc(50% - 50px);
  background-repeat: no-repeat;
}

.slate-pane-header-fixed-image,
.slate-pane-header-image {
  height: 600px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform;
  transform: translate3d(0px, 0px, 0px);
  background-position: 50% 100%;
  background-repeat: no-repeat;
}

.slate-pane-header-fixed-image > div,
.slate-pane-header-image > div {
  width: 100%;
  height: 400px;
  background-size: cover;
  background-position: 50% calc(50% - 50px);
  position: absolute;
  top: 300px;
  transform-origin: center top;
  background-repeat: no-repeat;
}

.slate-pane-header-fixed-image > div {
  top: 2px;
}

.slate-pane-header-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 70px;
  box-shadow: inset 0 -85px 30px -50px rgba(53, 53, 53, 0.5);
  color: #ffffff;
  padding: 5px 15px;
  z-index: 16;
}

.slate-pane-header-fixed-image {
  background-position: 50% calc(50% - 148px);
  top: -1px;
}

.slate-pane-header-top-shadow {
  position: absolute;
  top: -1px;
  width: 100%;
  height: 75px;
  box-shadow: inset 0 85px 30px -50px rgba(53, 53, 53, 0.5);
  z-index: 15;
}

.slate-pane-header-top-shadow.hidden {
  display: none;
}

.slate-title {
  height: 30px;
  line-height: 30px;
  font-size: 24px;
  width: calc(100% - 60px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.slate-account-name {
  font-size: 12px;
  position: relative;
  top: 2px;
}

.slate-pane-header-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.slate-pane-actions {
  position: relative;
  right: -10px;
}

.slate-pane-actions > div {
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 18px;
}

.slate-pane-wrapper .company-logo {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 16;
  opacity: 1;
  transition: opacity 0.1s;
  transform: translate3d(0px, 0px, 0px);
}

.slate-pane-wrapper .company-logo.hidden {
  opacity: 0;
  transition: opacity 0.1s;
}

.slate-pane-do-actions {
  position: absolute;
  right: 5px;
  top: 10px;
  z-index: 1000;
  color: #ffffff;
  font-size: 18px;
  transform: translate3d(0px, 0px, 0px);
}

.slate-pane-do-action {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 18px;
  display: inline-block;
}

.slate-pane-without-cover .slate-pane-content {
  top: 75px;
}

.slate-pane-header-fixed {
  background-color: #414042;
}

.slate-pane-chevron {
  display: block;
  width: 60px;
  height: 30px;
  background: url(/images/chevron.png) no-repeat;
  background-size: 60px auto;
  background-position: center;
  position: absolute;
  top: 10px;
  left: calc(50% - 30px);
  z-index: 16;
  opacity: 1;
  transition: opacity 0.1s;
  transform: translate3d(0px, 0px, 0px);
}

.slate-pane-icon {
  /* color: #696869; */
  color: #ffffff;
  opacity: 0.6;
  transition: all 0.2s;
  display: inline-block;
  cursor: pointer;
  width: 36px;
}

.slate-pane-pin {
  display: none;
}
.slate-pane-close {
  display: inline-block;
}

.slate-pane-prev,
.slate-pane-next {
  font-size: 22px;
}

.slate-pane-icon-disabled {
  opacity: 0.3;
}

.slate-pane-icon:not(.slate-pane-icon-disabled):hover {
  opacity: 1;
}

.slate-pane {
  overflow: hidden;
  width: 100%;
  box-shadow: -6px 0px 6px rgba(0, 0, 0, 0.15);
  transform: translateY(105%);
}

.slate-pane-dialog {
  position: relative;
  min-height: 370px;
  max-height: 70vh;
  overflow-y: auto;
  padding: 5px 0;
  background-color: #eee;
  font-size: 14px;
}

.slate-pane-dialog-empty {
  font-size: 16px;
  padding-top: 20px;
}

.slate-pane-powered-container {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slate-pane-powered-text {
  margin-right: 6px;
  font-size: 12px;
  font-weight: 500;
  font-style: italic;
  color: #414042;
}

.slate-pane-powered-img {
  width: 100px;
}

@media screen and (max-width: 480px) {
  .slate-pane {
    width: 100%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
}

@media screen and (min-width: 600px) {
  .slate-full-page-for-not-pane-wrap {
    height: 100%;
    max-height: 1020px;
    max-width: 620px;
    box-shadow: 3px 2px 16px 0px #000000;
    border-radius: 15px;
    border: 5px solid #001b38;
  }
  .slate-full-page-container {
    padding: 15px;
  }
}

@media screen and (min-height: 1000px) {
  .slate-full-page-container {
    padding: 15px;
  }
}

@media screen and (max-width: 990px)  {
  .slate-pane-chevron.hidden {
    opacity: 0;
    transition: opacity 0.1s;
  }
}

@media screen and (min-width: 991px) {
  .slate-pane-pin {
    display: inline-block;
  }

  .slate-pane-close {
    display: none;
  }

  .slate-pane {
    width: 400px;
    transform: translate(460px);
    transition: none;
  }

  .slate-pane slate-full-page-wrap {
    width: 100%;
    height: calc(100% - 36px);
  }

  .slate-pane.slate-pane-active {
    transform: translate(0px);
  }

  .slate-pane.slate-pane-hidden {
    transform: translate(350px);
    opacity: 0.6;
  }

  .slate-pane.slate-pane-hidden:hover {
    opacity: 1;
  }

  .slate-pane.slate-pane-docked {
    transform: translate(0px);
    transition: none;
    box-shadow: none;
  }

  .slate-pane.slate-pane-docked .slate-pane-pin {
    color: #f15a29;
    opacity: 1;
  }

  .slate-pane-inner-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;
    z-index: 50;
  }
  .slate-pane-overlay {
    display: block;
  }
  .slate-pane-chevron {
    display: none;
  }
}
