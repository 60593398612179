.preloader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  overflow: hidden;
  background-color: rgba(238, 238, 238, 0.8);
}

.preloader-inner {
  -webkit-animation: anim 2s infinite linear;
  animation: anim 2s infinite linear;
  width: 51px;
  height: 44px;
  background-size: 51px 44px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -25px;
}

@-webkit-keyframes anim {
  from {
    -webkit-transform: rotateY(0deg);
  }
  to {
    -webkit-transform: rotateY(360deg);
  }
}

@keyframes anim {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}
