.notifications-table {
  height: calc(100% - 70px);
  min-height: calc(100% - 70px);
}
.notifications-container {
  height: calc(100% - 31px);
  min-height: calc(100% - 31px);
  display: flex;
  flex-direction: column;
}

.basic-info-settings-record-selector {
  position: relative;
  max-width: 360px;
  margin-top: 20px;
}

.email-list {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.email-list-item {
  padding-left: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
  min-height: 60px;
  background-color: #eee;
  margin: 10px 0;
}
.popup-email-input-wrapper {
  padding: 10px;
}

.alert-item-container {
  padding: 15px;
}

.alerts-list-item {
  padding-left: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-height: 76px;
  background-color: #eee;
  margin: 10px 0;
}

.alerts-list-item-with-error {
  height: 96px;
  background-color: rgba(255, 145, 145, 0.38);
}

.alert-list-input-wrapper {
  display: flex;
  align-items: center;
}

.alert-pattern-selector {
  padding: 10px 15px;
}
.alert-text-field-container{
  width: 120px;
  padding-right: 15px;
  margin-top: -8px;
}
.alert-time-title {
  padding-top: 25px;
}
.alert-error-title {
  padding-top: 25px;
  color: #f15a29;
}

.basic-info-container{
  display: flex;
  flex-direction: column;
}

.available-rule-fields {
  max-height: 360px;
  overflow-y: auto;
}

.base-info-selector {
  position: relative;
  max-width: 360px;
  margin-top: 30px;
}
