.scrollable-table {
  height: 100%;
  position: relative;
}

.header-grid {
  overflow: hidden !important;
  background: #414042;
  color: #aaaaaa;
  outline: none !important;
}

.header-drag,
.header-drag .header-cell .header-drag .header-cell-filter,
.header-drag .react-resizable-handle {
  cursor: all-scroll;
}

.header-resize,
.header-resize .header-cell,
.header-resize .header-cell-filter,
.header-resize .react-resizable-handle {
  cursor: col-resize;
}

.body-grid {
  border: 1px solid #e6e6e6;
  border-top: 0;
  outline: none !important;
}

.header-row {
  display: block;
}

.header-cell {
  display: flex;
  align-items: stretch;
  padding: 0 16px 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  background: #565556;
  height: 36px;
}

.header-cell.without-ordering {
  cursor: default;
}

.header-cell:before {
  display: block;
  content: '';
  height: 100%;
  background: #565556;
  position: absolute;
  left: -7px;
  width: 8px;
}

.header-grid .header-cell {
  background: #414142;
}

.header-grid .header-cell:before {
  display: none;
}

.header-cell-fixed {
  display: inline-block;
  vertical-align: top;
  width: 32px;
  height: 36px;
  padding: 0;
  border-right: 1px solid #565556;
}

.header-cell-title {
  line-height: 36px;
  width: calc(100% - 28px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.without-ordering .header-cell-title {
  padding-left: 5px;
}

.header-cell-actions {
  max-width: 44px;
  display: flex;
  flex-flow: row-reverse;
}

.header-cell-sort,
.header-cell-filter {
  line-height: 36px;
  text-align: center;
}

.header-cell-sort {
  width: 14px;
}

.header-cell-filter {
  width: 30px;
}

.body-row {
  display: flex;
  background: #f7f6f6;
  cursor: pointer;
}

.body-row:last-child .body-row-back {
  border-bottom: 1px solid #eeeeee;
}

.body-row.even .body-row-back {
  background: #ffffff;
}

.body-row.body-row-selected .body-row-back {
  background: #a6d4f2;
  border-bottom: 1px solid #69b6ea;
}

.body-row.body-row-selected .body-cell-fixed {
  background: #2196f3;
}

.body-row.body-row-selected-first .body-row-back {
  border-top: 1px solid #69b6ea;
}

.body-row.body-row-selected .body-cell:after {
  background: #69b6ea;
}

.body-cell {
  position: relative;
  padding: 0 15px 0 4px;
  display: flex;
  align-items: center;
}

.body-cell.medium-height {
  line-height: 50px;
}

.body-cell.large-height {
  line-height: 120px;
}

.body-cell-fixed {
  justify-content: center;
  position: relative;
  width: 31px;
  height: auto;
  padding: 0;
  font-size: 10px;
  color: #c0c0c0;
  overflow: hidden;
}

.body-cell-fixed.medium-height {
  height: 70px;
}

.body-cell-fixed.large-height {
  height: 140px;
}

.body-cell-fixed-newline {
  position: absolute;
  top: -36px;
}

.body-cell-fixed-newline.medium-height {
  top: -70px;
}

.body-cell-fixed-newline.large-height {
  top: -140px;
}

.body-cell:nth-child(3) {
  padding-left: 10px;
}

.body-cell:after {
  content: '';
  display: block;
  height: 100%;
  width: 1px;
  background: #eeeeee;
  position: absolute;
  right: 6px;
  top: 0;
}

.body-cell.medium-height:after {
  height: 70px;
}

.body-cell.large-height:after {
  height: 140px;
}

.body-cell-fixed:after {
  right: 0;
}

.body-cell:last-child:after {
  display: none;
}

.body-cell:last-child {
  border-right: 0;
}

.body-cell-preview {
  width: 60%;
  background: #d9d9d9;
  height: 10px;
  border-radius: 4px;
  position: relative;
  top: 3px;
}

.body-cell-value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.body-cell-image {
  overflow: hidden;
}

.body-cell-value-inner-email {
  cursor: text;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 7px 0;
}

.body-cell-value-inner-email-container {
  display: flex;
  flex-direction: column;
}

/* for clipboard purposes */
.body-cell-value-inner {
  cursor: text;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.react-resizable {
  position: relative;
  opacity: 1 !important;
  z-index: 500;
  display: inline-block;
  vertical-align: top;
}

.header-row .react-resizable:last-child .header-cell {
  border-right: 0;
}

.react-resizable-handle {
  height: 36px !important;
  width: 16px !important;
  cursor: col-resize;
  background: none;
  right: 0;
  z-index: 9;
  padding: 0;
}

.without-ordering + .react-resizable-handle {
  cursor: default;
}

.react-resizable-handle:before {
  content: '';
  display: block;
  height: 100%;
  width: 8px;
  background: #414142;
  position: absolute;
  right: 0;
}

.react-resizable-handle:after {
  content: '';
  display: block;
  height: 100%;
  width: 1px;
  background: #565556;
  position: absolute;
  bottom: 0;
  right: 6px;
}

/* LOADING TABLE */
.loading-table {
  height: 100%;
  position: relative;
}

.loading-table-header,
.loading-table-row {
  display: flex;
  height: 36px;
}

.loading-table-header {
  background-color: #414142;
}

.loading-table-body {
  height: 100%;
  overflow-y: auto;
  border: 1px solid #e6e6e6;
}

.loading-table-row {
  border-bottom: 1px solid #eeeeee;
}

.loading-table-row.even {
  background-color: #ffffff;
}

.loading-table-header-cell-fixed,
.loading-table-row-cell-fixed {
  width: 30px;
}

.loading-table-header-cell,
.loading-table-row-cell {
  /* width: calc(100% / 5); */
  width: 150px;
  padding: 10px 0 0 10px;
}

.loading-table-header-cell:last-child,
.loading-table-row-cell:last-child {
}

.loading-table-header-cell {
  border-left: 1px solid #565556;
}

.loading-table-row-cell {
  border-left: 1px solid #eeeeee;
}

.first-letter-image {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 24px;
  vertical-align: middle;
  text-align: center;
  border-radius: 5px;
  background-color: rgb(230, 230, 230);
  color: rgb(192, 192, 192);
  border: 1px solid rgb(192, 192, 192);
}

.image-preview-large {
  width: 120px;
  height: 120px;
  line-height: 120px;
}

@media screen and (max-width: 990px) {
  .scrollable-table,
  .loading-table {
    overflow: hidden;
  }
}

@media (hover: hover) {
  .body-row:hover .body-row-back {
    background: #d6d6d6;
  }

  .body-row:hover .body-cell:after {
    background: #c0c0c0;
  }

  .body-row.body-row-selected:hover .body-row-back {
    background: #88c5ee;
  }

  .body-row.body-row-selected:hover .body-cell:after {
    background: #2196f3;
  }
}

/*
z-index value must be > 1500 (dialog's z-index).
Overriding Lightbox's z-index (not able to do it via props).
*/
.ReactModal__Overlay {
  z-index: 1501 !important;
}
