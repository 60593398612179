.layout-sign .page {
  top: 0;
  width: 100%;
}

.layout-sign .container {
  height: 100%;
}

.layout-sign .content {
  /* height: 100%; */
  padding: 0;
}

.layout-sign .content.content-create-profile {
  height: auto;
}

.layout-sign .content {
  min-height: 100%;
  width: 480px;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  /*padding-bottom: 50px;*/
}

.layout-sign h1 {
  /*---- global font setting ----*/
  font-weight: 400;
  font-size: 1.3em;
  display: block;
  text-align: center;
}

.layout-sign .btn {
  display: block !important;
  max-width: 210px !important;
  min-width: 0 !important;
  margin: 20px auto !important;
}

.layout-sign .verification-2fa-code-container .verification-code-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
}

.layout-sign .verification-2fa-code-container .verification-2fa-code-link-container {
  display: flex;
  gap: 10px;
  align-items: center;
  height: 24px;
  flex-wrap: nowrap;
  margin: 6px 0 12px;
}

.verification-2fa-code-link {
  font-size: 11px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-block-start: 0px;
  margin-block-end: 0px;
}

.verification-2fa-code-link-disabled {
  color: gray;
  cursor: initial;
  text-decoration: none;
}

.badge-verified {
  margin-right: 12px;
}

.layout-sign .verification-2fa-code-container .verification-code-button .btn {
  margin: 0 !important;
}

.layout-sign .btn-verification .btn {
  max-width: 260px !important;
}

.layout-sign .btn-verification-sm {
  display: none;
}

.layout-sign .left-pane {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: calc(100% - 480px);
  color: #ffffff;
  background: url(/images/Login-Background.jpg) no-repeat center;
  background-size: cover;
  z-index: 1;
  transition: all 0.3s;
}

.sign-card-wrapper {
  width: 100%;
}

.sign-card-wrapper .card {
  max-width: 400px;
}

.layout-sign-switch {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 0 30px 0;
  font-size: 0.86em;
  text-align: center;
}

.layout-sign-switch-create-profile {
  position: relative;
}

.layout-sign .footer {
  display: none;
}

.sign-card-wrapper > div:first-child {
  margin: 0 auto;
  width: 100%;
}

.sign-logo {
  background: url(/images/Login-SL8-Logo.png) no-repeat center;
  background-size: 180px 26px;
  width: 180px;
  height: 26px;
  position: absolute;
  top: 2.15em;
  left: 2.15em;
  cursor: pointer;
}

.sign-content {
  height: calc(100% - 150px);
  margin-top: 6.5em;
  display: flex;
  align-items: center;
}

.sign-items {
  margin: 0 10em 0 4em;
}

.sign-item {
  display: flex;
  margin: 2.85em 0;
}

.sign-item-icon {
  font-size: 24px;
  width: 50px;
  margin-right: 25px;
  text-align: right;
}

.sign-item-desc {
  width: calc(100% - 75px);
}

.sign-item-title {
  font-size: 1.7em;
}

.sign-item-text {
  font-size: 1em;
  /*---- global font setting ----*/
  font-weight: 400;
  line-height: 1.5em;
}

.sign-item-text a {
  color: #ffffff;
  /*---- global font setting ----*/
  font-weight: 400;
}

.sign-item-dashboard {
  margin-top: 2.85em;
  margin-bottom: 70px;
}

.sign-item-dashboard .sign-item-icon {
  font-size: 44px;
  position: relative;
  top: -2px;
}

.sign-item-dashboard .sign-item-title {
  font-size: 44px;
  position: relative;
  left: -4px;
}

.animation {
  justify-content: center;
  animation: 0.5s fade-in;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .left-pane {
    background: url(/images/Login-Background@2x.jpg) no-repeat center;
    background-size: cover;
  }

  .sign-logo {
    background: url(/images/Login-SL8-Logo@2x.png) no-repeat center;
    background-size: 180px 26px;
  }
}

@media screen and (max-width: 1199px) {
  .sign-item-dashboard {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 1099px) {
  .sign-items {
    margin: 0 4em 0 2em;
  }

  .sign-item {
    margin: 2.5em 0;
  }

  .sign-item-icon {
    font-size: 20px;
  }

  .sign-item-title {
    font-size: 1.5em;
  }

  .sign-item-dashboard {
    margin-top: 30px;
    margin-bottom: 40px;
  }

  .sign-item-dashboard .sign-item-icon,
  .sign-item-dashboard .sign-item-title {
    font-size: 36px;
  }
}

@media screen and (max-width: 1023px) {
  .sign-card-wrapper .card {
    width: 90% !important;
  }
  .layout-sign .left-pane,
  .layout-sign .content {
    width: 50%;
  }

  .sign-content {
    height: calc(100% - 115px);
  }

  .sign-logo {
    width: 160px;
    background-size: 160px auto;
    top: 1em;
    left: 1em;
  }

  .sign-content {
    margin-top: 4em;
  }

  .sign-items {
    margin: 0 3em 0 1em;
  }

  .sign-item-icon {
    width: 40px;
  }

  .sign-item-dashboard .sign-item-icon,
  .sign-item-dashboard .sign-item-title {
    font-size: 30px;
  }
}

@media screen and (max-width: 767px) {
  .layout-sign .content {
    width: 100%;
    padding-top: 60px;
    position: relative;
    min-height: calc(100% - 70px);
    height: auto;
  }

  /* .layout-sign .content {
    height: 100%;
  } */

  .layout-sign .footer {
    display: block;
    padding-bottom: 20px;
  }

  .layout-sign .sign-content {
    display: none;
  }

  .layout-sign .left-pane {
    display: block;
    width: 100%;
  }

  .layout-sign .left-pane {
    padding: 15px 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: auto;
    z-index: 2;
  }

  .sign-logo {
    position: relative;
    top: auto;
    left: auto;
    margin: 0 auto;
  }

  .layout-sign {
    min-height: 100%;
    height: 100%;
    display: block;
  }

  .layout-sign-switch {
    position: relative;
    text-align: center;
    padding-top: 20px;
  }
}

@media screen and (max-width: 479px) {
  .layout-sign .btn-verification {
    display: none;
  }

  .layout-sign .btn-verification-sm {
    display: block;
  }
}
